import { loginPageController, translate } from 'magner';
import loginRequest from './requests';
import {
  APP_COMMON, APP_ID, APP_MILLIARD, COMMON_LIST, SUB_DOMEN,
} from '~/constants';

const loginPageConfig = loginPageController({
  title: translate('hubstr.login.title'),
  request: loginRequest,
  logo: APP_ID === APP_COMMON && SUB_DOMEN && COMMON_LIST.includes(SUB_DOMEN)
    ? `/logo/${SUB_DOMEN}/logo.svg`
    : APP_ID !== APP_COMMON
      ? `/logo/${APP_ID}/logo.svg`
      : '/logo/hubstr/logo.svg',
  form: {
    submitOnEnter: true,
    fullDataOnUpdate: true,

    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.login.submitButton'),
        },
      },
    ],
    layout: {
      type: 'row',
      props: {},
      layout: [{
        type: 'column',
        props: {},
        fields: [
          {
            type: 'input-phone',
            name: 'login',
            label: translate('hubstr.login.fields.phone.label'),
            props: {
              autocomplete: 'on',
              preferredCountries: ['ru', 'kz', 'uz', 'am'],
              initialCountry: 'ru',
              hasWithoutMaskOption: true,
            },
            validation: {
              type: 'empty',
              trigger: 'blur',
            },
          },
          {
            type: 'input',
            name: 'password',
            label: translate('hubstr.login.fields.password.label'),
            props: {
              type: 'password',
              autocomplete: 'on',
              placeholder: translate('hubstr.login.fields.password.placeholder'),
              prefixIcon: () => import('./lock.svg'),
            },
            /* validation: {
              type: 'password',
              trigger: 'blur',
            }, */
          },
        ],
      }],
    },
  },
});

export default loginPageConfig;
