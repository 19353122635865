import { AdditionalField } from 'features/settings/mobile-editor/interfaces';

export default class MobilePrivacyEntity {
  additional: AdditionalField;

  constructor (additional: AdditionalField) {
    this.additional = additional;
  }

  setDefaultPrivacy () {
    return {
      type: 'privacyList',
      name: `fieldsVisibility[${this.additional.code}]`,
      value: `data.fieldsVisibility.${this.additional.code}`,
      title: 'Приватность',
      list: {
        1: 'Показать',
        0: 'Скрыть',
      },
      default: '1',
    };
  }

  setRangePrivacy () {
    return {
      type: 'privacyList',
      name: `additions[${this.additional.code}Display]`,
      value: `data.additions.${this.additional.code}Display`,
      title: 'Приватность',
      list: {
        STRICT: 'Показывать',
        RANGE: 'Только диапазон',
        NONE: 'Скрывать',
      },
      default: 'STRICT',
    };
  }

  setPhonePrivacy (value?: boolean) {
    return {
      type: 'privacyBool',
      name: 'hidePhone',
      value: 'data.fieldsVisibility.phone',
      title: 'Приватность',
      list: {
        0: 'Показать',
        1: 'Скрыть',
      },
      default: value ? '0' : '1',
    };
  }

  create (format: Pick<AdditionalField, 'format'> | string) {
    switch (format) {
      case 'EXPERIENCE': case 'EMPLOYEES': case 'TURNOVER':
        return this.setRangePrivacy();
      case 'DEFAULT':
        return this.setDefaultPrivacy();
    }
  }
}
