<template>
  <el-row style="margin-top: 20px;">
    <el-col v-for="option in FILE_TYPE_OPTIONS" :key="option.value" :span="24/FILE_TYPE_OPTIONS.length">
      <el-checkbox
        v-model="state[option.value]"
        :label="translateText(option.label)"
        :disabled="onlyMeta"
        @change="emitData"
      />
    </el-col>
  </el-row>
  <el-row style="margin-top: 20px;">
    <el-col>
      <p>{{ `${translateText('gaz.interview.file_question.max_size')}:` }}</p>
      <el-input-number
        v-model="state.maxSize"
        :class="[{'is-error': errors.maxSize && showErrors}]"
        :disabled="onlyMeta"
        @update:modelValue="emitData"
      />
    </el-col>
  </el-row>
  <el-row style="margin-top: 20px;">
    <el-col>
      <p>{{ translateText('gaz.interview.file_question.warning_1') }}</p>
      <p>{{ translateText('gaz.interview.file_question.warning_2') }}</p>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive, watch,
} from 'vue';
import { IFileQuestion } from 'features/settings/interviews/interfaces';
import { FILE_TYPE_OPTIONS } from 'features/settings/interviews/constants';
import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'UploadQuestion',
  props: {
    data: {
      type: Object as PropType<IFileQuestion>,
      required: true,
    },
    showErrors: Boolean,
    onlyMeta: Boolean,
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const { customT } = useTranslate();

    const state: Partial<IFileQuestion> = reactive({
      maxSize: props.data.maxSize ?? 1,
      photo: props.data.photo ?? true,
      document: props.data.document ?? true,
      audio: props.data.audio ?? true,
      video: props.data.video ?? true,
    });
    const errors: {
      maxSize: boolean
    } = {
      maxSize: false,
    };
    /**
     *
     */
    watch(() => props.data, () => {
      state.maxSize = props.data.maxSize ?? 1;
      state.photo = props.data.photo ?? true;
      state.document = props.data.document ?? true;
    });
    /**
     *
     */
    const emitData = () => {
      errors.maxSize = state.maxSize === 0;

      emit('update:data', { data: state, errors });
    };
    /**
     *
     */
    onMounted(() => {
      setTimeout(() => {
        emitData();
      });
    });

    return {
      state,
      errors,

      FILE_TYPE_OPTIONS,
      emitData,
      translateText: (code: string) => customT(translate(code)),
    };
  },
});
</script>
