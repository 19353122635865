<template>
  <div class="m-general-settings">
    <div class="m-general-settings__title">
      Авторизация
    </div>
    <div class="m-general-settings__content">
      <el-form-item label="Кол-во одновременно залогинненых устройств">
        <el-input v-model="state.maxUserDevicesCount.value" />
      </el-form-item>
    </div>
    <div class="m-general-settings__title">
      Посты
    </div>
    <div class="m-general-settings__content">
      <el-form label-position="right">
        <el-form-item class="checkbox" label="Включить возможность запиненых постов">
          <el-checkbox v-model="state.postPinEnabled.value" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="analytics">
      <div class="m-general-settings__title">
        Аналитика
      </div>
      <div class="m-general-settings__content">
        <el-form label-position="right">
          <el-form-item class="checkbox" label="Включить аналитику">
            <el-checkbox v-model="state.analyticCollectorEnabled.value" />
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'MGeneralSettings',
  props: {
    data: {
      type: Object,
      default: null,
    },
    analytics: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const state = ref(props.data);

    return {
      state,
    };
  },
});
</script>

<style lang="scss" scoped>
.m-general-settings {
  &__title {
    font-size: var(--el-font-size-large);
  }
  &__content {
    margin-top: 20px;
    & .checkbox {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: row-reverse;
      width: max-content;
    }
  }
}
</style>
