<template>
  <el-alert title="Только просмотр." type="success" :closable="false" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GroupButton',
});
</script>

<style scoped>

</style>
