<template>
  <el-button
    type="text"
    @click="clickButton(row.id)"
  >
    {{ `${translateText('hubstr.reports.table.download')}` }}
  </el-button>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { translate, useTranslate } from 'magner';
import { API_URL } from '~/constants';

export default defineComponent({
  name: 'EventDownloadLink',
  props: {
    row: {
      type: Object as PropType<any>,
      required: true,
    },
    column: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup (props) {
    const { customT } = useTranslate();
    const ls = JSON.parse(localStorage.getItem('magner-hubstr') || '');
    const token = ls.token;
    // @ts-ignore
    const clickButton = (eventId: string) => {
      const apiUrl = props.row.hasPollPrice
        ? `${API_URL}/api/admin/analytics/gaz/export/event/${eventId}`
        : `${API_URL}/api/admin/analytics/gaz/export/event_poll/${eventId}`;
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.blob();
          }
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          // @ts-ignore
          a.download = `отчет_мероприятиe_${eventId}.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    };

    const translateText = (code: string) => customT(translate(code));

    return {
      clickButton,
      translateText,
    };
  },
});
</script>
