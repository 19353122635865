<template>
  <el-row align="middle" justify="center" style="display: flex; width: 100%">
    <el-col
      :xl="16"
      :lg="15"
      :md="24"
      :xs="24"
    >
      <div class="interview__layout">
        <div
          class="interview__header"
        >
          <div class="interview__header-container">
            <div style="flex: 1 1 0; display: flex; align-items: center; gap: 15px;">
              <el-input
                v-model="state.name"
                style="max-width: 560px;"
                :placeholder="translateText('gaz.interview.layout.name')"
                :class="[{'is-error': state.showErrors && state.questionsErrors.rootName }]"
                @change="emitData"
              >
                <template v-if="state.showErrors && state.questionsErrors.rootName " #suffix>
                  <el-icon color="#FF0000" size="20">
                    <component :is="Warning" />
                  </el-icon>
                </template>
              </el-input>
              <div>
                <export-button
                  style="max-width: 100px"
                  :row="{id: state.id, published: state.onlyMeta}"
                />
              </div>
            </div>
            <div class="interview__header-container-right">
              <div>
                <div class="interview__header-config">
                  <div style="display:flex; align-items: center;">
                    <el-checkbox
                      v-model="state.globalTotalLimitActive"
                      :label="translateText('gaz.interview.layout.global_limit')"
                      :disabled="state.onlyMeta"
                      @update:modelValue="emitData"
                    />
                    <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
                      <svg-icon
                        size="sm"
                        class="generic-form_item_help"
                        core="question"
                      />
                      <template #content>
                        {{ translateText('gaz.interview.layout.global_limit_hint') }}
                      </template>
                    </el-tooltip>
                  </div>
                  <div class="interview__header-config-price">
                    <el-input-number
                      v-model="state.totalLimit"
                      :min="state.totalLimitMin"
                      :disabled="!state.globalTotalLimitActive"
                      @update:modelValue="emitData"
                    />
                  </div>
                </div>
              </div>
              <div v-if="!field.moneySettingsHidden" style="text-align: right;" @click="openAddPriceModal">
                <el-button class="money-button mb-0">₽</el-button>
              </div>
            </div>
          </div>
          <el-row style="margin-top: 20px;">
            <el-col :span="24">
              <el-input
                v-model="state.description"
                :placeholder="translateText('gaz.interview.layout.description')"
                :class="[{'is-error': state.showErrors && state.questionsErrors.rootDescription }]"
                @change="emitData"
              >
                <template v-if="state.showErrors && state.questionsErrors.rootDescription" #suffix>
                  <el-icon color="#FF0000" size="20">
                    <component :is="Warning" />
                  </el-icon>
                </template>
              </el-input>
            </el-col>
          </el-row>
        </div>

        <div class="interview__body">
          <template
            v-for="step in state.steps"
            :key="step.uuid"
          >
            <el-container class="interview-step">
              <el-header class="px-0">
                <el-row>
                  <el-col :span="12" class="interview-step__center">
                    <el-button
                      style="width: auto;margin: 0 10px 0 0;vertical-align: top;height: 24px"
                      :type="step.collapsed ? 'info' : 'primary'"
                      :icon="step.collapsed ? View : Hide"
                      size="small"
                      circle
                      @click="toggleCollapsed(step.uuid)"
                    />
                    <h3 style="display: inline-block; vertical-align: top">{{ `${translateText('gaz.interview.layout.step')} ${step.serial}` }}</h3>
                  </el-col>
                  <el-col :span="12" style="text-align: right">
                    <el-button-group v-if="!state.onlyMeta" class="interview-step__center">
                      <el-button
                        style="width: auto;"
                        :disabled="step.serial === 1"
                        type="primary"
                        :icon="ArrowUpBold"
                        plain
                        @click="moveStepUp(step.uuid)"
                      />
                      <el-button
                        style="width: auto;"
                        :disabled="step.serial === state.steps.length"
                        type="primary"
                        :icon="ArrowDownBold"
                        plain
                        @click="moveStepDown(step.uuid)"
                      />
                      <el-button
                        v-if="step.canHaveConditions"
                        style="width: auto;"
                        type="info"
                        :icon="QuestionFilled"
                        plain
                        @click="stepAddCondition(step)"
                      />
                      <el-button
                        style="width: auto;"
                        type="danger"
                        :icon="Delete"
                        plain
                        @click="deleteStep(step.uuid)"
                      />
                    </el-button-group>
                  </el-col>
                </el-row>
              </el-header>

              <el-main v-show="!step.collapsed" class="px-2">
                <condition-card
                  v-if="step.stepCondition && step.stepCondition.length"
                  :conditions-data="step.stepCondition"
                  :condition-entity-uuid="step.uuid"
                  entity-type="step"
                  :steps="state.steps.filter((s) => s.serial <= step.serial)"
                  :only-meta="state.onlyMeta"
                  @update:conditionsData="stepConditionDataUpdated($event, step)"
                />

                <template
                  v-for="question in step.questions"
                  :key="question.uuid"
                >
                  <interview-question
                    :id="`${question.uuid}-${question.stepSerial}`"
                    :data="question"
                    :is-first="question.stepSerial === 1"
                    :is-last="question.stepSerial === step.questions.length"
                    :can-add-conditions="(step.serial > 1 || question.serial > 1)
                      && (!question.conditions || !question.conditions.length)"
                    :show-errors="state.showErrors"
                    :only-meta="state.onlyMeta"
                    :prices="state.prices"
                    :money-settings-hidden="field.moneySettingsHidden"
                    @update:data="questionDataUpdated($event, step.uuid, question.uuid)"
                    @delete="deleteQuestion(step.uuid, question.uuid)"
                    @move-up="moveQuestionUp(step.uuid, question.uuid)"
                    @move-down="moveQuestionDown(step.uuid, question.uuid)"
                    @add-conditions="questionAddConditions(step.uuid, question.uuid)"
                    @errors="questionGotErrors($event, question.uuid)"
                  />

                  <condition-card
                    v-if="question.conditions && question.conditions.length"
                    :conditions-data="question.conditions"
                    :condition-entity-uuid="question.uuid"
                    entity-type="question"
                    :steps="state.steps.map((s) =>
                      ({
                        ...s,
                        questions: s.uuid === step.uuid
                          ? s.questions.filter((q) => q.serial < question.serial)
                          : s.questions
                      }))
                      .filter((s) => s.serial <= step.serial)"
                    :only-meta="state.onlyMeta"
                    @update:conditionsData="conditionsDataUpdated($event, question)"
                  />
                </template>
              </el-main>

              <el-footer v-if="!state.onlyMeta && !step.collapsed">
                <el-button
                  :icon="Plus"
                  round
                  style="width: 100px"
                  @click="openAddQuestionModal(step.uuid)"
                >
                  {{ translateText('gaz.interview.components.condition.question_select.question_label') }}
                </el-button>
              </el-footer>
            </el-container>
          </template>

          <div v-if="!state.onlyMeta" class="new-step-btn-container">
            <el-button
              :icon="Plus"
              round
              style="max-width: 100px;"
              @click="addStep"
            >
              {{ translateText('gaz.interview.layout.step') }}
            </el-button>
          </div>
        </div>
      </div>
    </el-col>

    <el-col
      :xl="8"
      :lg="9"
      :md="24"
      :xs="24"
    >
      <preview :model-value="state" class="interview__preview" />
    </el-col>
  </el-row>

  <el-dialog
    v-if="state.addQuestionModal.visible"
    v-model="state.addQuestionModal.visible"
    class="add-question-modal"
    :title="translateText('gaz.interview.layout.modal_question.title')"
  >
    <el-form style="padding: 10px">
      <el-form-item :label="translateText('gaz.interview.layout.modal_question.label')">
        <el-select
          v-model="state.addQuestionModal.selectedType"
          :placeholder="translateText('gaz.interview.layout.modal_question.title')"
        >
          <el-option
            v-for="questionType in QUESTION_TYPE_OPTIONS"
            :key="questionType.id"
            :label="translateText(questionType.name)"
            :value="questionType.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button style="width: auto;margin-right: 10px;" @click="state.addQuestionModal.visible = false">
          {{ translateText('gaz.interview.layout.modal_question.cancel') }}
        </el-button>
        <el-button style="width: auto;" type="primary" @click="addQuestion">
          {{ translateText('gaz.interview.layout.modal_question.ok') }}
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-if="state.addPriceModal.visible"
    v-model="state.addPriceModal.visible"
    :title="translateText('gaz.interview.layout.modal_price.title')"
  >
    <el-form style="width:100%;">
      <el-form-item>
        <el-radio-group
          :model-value="state.isEventPayable"
          :disabled="state.onlyMeta"
          @update:modelValue="changeInterviewPayable"
        >
          <el-radio
            :label="false"
          >
            {{ translateText('gaz.interview.layout.modal_price.free') }}
          </el-radio>
          <el-radio
            :label="true"
          >
            {{ translateText('gaz.interview.layout.modal_price.paid') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <hr style="margin: 15px 0;border-top: 1px solid #ccc; height: 0;">
    <template v-if="state.isEventPayable">
      <label style="display: block; margin-bottom: 15px">
        {{ `${translateText('gaz.interview.layout.modal_price.total_sum')} =` }}
      </label>
      <el-row v-if="state.prices.length" :gutter="10" style="width:100%;margin-bottom: 15px;">
        <el-col :span="8">
          <el-input :value="translateText('gaz.interview.layout.modal_price.base_price')" disabled />
        </el-col>
        <el-col :span="8">
          <el-input-number
            v-model="state.basePrice"
            :min="0"
            :disabled="state.onlyMeta"
            @update:modelValue="emitData"
          />
        </el-col>
      </el-row>
      <el-form style="width:100%;">
        <template v-for="price in state.prices" :key="price.id">
          <el-form-item>
            <el-row :gutter="10" style="width:100%;margin-bottom: 15px;">
              <el-col :span="8">
                <el-form-item>
                  <template #label>
                    {{ translateText('gaz.interview.layout.modal_price.question_name_label') }}
                  </template>
                  <el-input v-model="price.name" :disabled="state.onlyMeta" @update:modelValue="emitData" />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <template #label>
                    {{ translateText('gaz.interview.layout.modal_price.question_price_label') }}
                  </template>
                  <el-input-number
                    v-model="price.price"
                    :min="1"
                    :disabled="state.onlyMeta"
                    @update:modelValue="emitData"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <template #label>
                    {{ translateText('gaz.interview.layout.modal_price.question_question_label') }}
                    <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
                      <svg-icon
                        size="sm"
                        class="generic-form_item_help"
                        core="question"
                      />
                      <template #content>
                        {{ translateText('gaz.interview.layout.modal_price.question_hint') }}
                      </template>
                    </el-tooltip>
                  </template>

                  <el-select
                    v-model="price.questionId"
                    :hint="translateText('gaz.interview.layout.modal_price.question_hint')"
                    :placeholder="translateText('gaz.interview.layout.modal_price.question')"
                    :disabled="state.onlyMeta"
                    @update:modelValue="emitData"
                  >
                    <el-option
                      v-for="question in questionsWithLimits.filter(
                        (i: {questionId: string}) => i.questionId === price.questionId
                          || !state.prices.map((p) => p.questionId).includes(i.questionId)
                      )"
                      :key="question.questionId"
                      :label="question.name"
                      :value="question.questionId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-button
                  v-if="!state.onlyMeta"
                  style="width: auto;margin-bottom: 0;margin-top: 22px"
                  type="danger"
                  :icon="Delete"
                  plain
                  @click="deletePrice(price.id)"
                />
              </el-col>
            </el-row>
          </el-form-item>
        </template>
        <el-row v-if="!state.onlyMeta">
          <el-col :span="24">
            <el-button
              :icon="Plus"
              round
              style="width: 120px"
              @click="addPrice"
            >
              {{ translateText('gaz.interview.layout.modal_price.term') }}
            </el-button>
          </el-col>
        </el-row>
      </el-form>
      <p style="display: block;white-space: normal; word-wrap: normal">
        {{ translateText('gaz.interview.layout.modal_price.help_text') }}
      </p>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import {
  Plus,

  ArrowUpBold,
  ArrowDownBold,
  Delete,
  Hide,
  View,
  QuestionFilled,
  Warning,
} from '@element-plus/icons-vue';
import {
  computed,
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { magnerConfirm, translate, useTranslate } from 'magner';
import InterviewQuestion from 'features/settings/interviews/components/question/index.vue';
import {
  IConditionBlock,
  IQuestion, IQuestionType,
  IInterview, IStep, IListQuestion, IPrice, IInterviewState,
} from 'features/settings/interviews/interfaces';
import { DATA_TYPE, QUESTION_TYPE, QUESTION_TYPE_OPTIONS } from 'features/settings/interviews/constants';
import ConditionCard from 'features/settings/interviews/components/condition/index.vue';
import { ElMessageBox } from 'element-plus';
import ExportButton from 'features/settings/interviews/components/exportButton.vue';
import Preview from './preview.vue';

export default defineComponent({
  name: 'InterviewLayout',
  components: {
    ExportButton,
    ConditionCard,
    InterviewQuestion,
    Preview,
  },
  props: {
    modelValue: {
      type: Object as PropType<IInterview>,
      required: true,
    },
    field: {
      type: Object as PropType<{moneySettingsHidden: boolean}>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { customT } = useTranslate();
    const state: IInterviewState = reactive({
      id: props.modelValue.id,
      name: props.modelValue.name,
      description: props.modelValue.description,
      globalTotalLimitActive: !!props.modelValue.totalLimit ?? false,
      basePrice: props.modelValue.basePrice ?? 0,
      totalLimit: props.modelValue.totalLimit ?? 1,
      totalLimitMin: props.modelValue.isPublished && props.modelValue.totalLimit ? props.modelValue.totalLimit : 1,
      steps: props.modelValue.steps,
      addQuestionModal: {
        visible: false,
        selectedType: QUESTION_TYPE_OPTIONS[0].id,
        currentStepId: '',
      },
      addPriceModal: {
        visible: false,
      },
      prices: [],
      isEventPayable: (props.modelValue.prices && props.modelValue.prices.length > 0) ?? false,

      nameError: false,
      descriptionError: false,
      showErrors: false,
      questionsErrors: {},
      onlyMeta: props.modelValue.isPublished,
      canDelete: props.modelValue.canDelete,
    });
    /**
     * Парсим цены в сущности формы редактирования
     */
    if (props.modelValue.prices && props.modelValue.prices.length > 0) {
      props.modelValue.prices.map((p) => {
        state.prices.push({
          id: uuidv4() as string,
          questionId: p.questionId,
          price: p.price,
          name: p.name,
        });

        return p;
      });
    }
    /**
     *
     */
    watch(() => props.modelValue, () => {
      state.showErrors = props.modelValue.showErrors ?? false;
    });
    /**
     * true - есть хотя бы одно условие во всем опроснике
     */
    const interviewHasConditions = computed(() => {
      const has = ref(false);

      state.steps.map((s: IStep) => {
        if (s.stepCondition && s.stepCondition.length) {
          has.value = true;
        }

        s.questions.map((q: IQuestion) => {
          if (q.conditions && q.conditions.length) {
            has.value = true;
          }

          return q;
        });

        return s;
      });

      return has.value;
    });
    /**
     * если id не указан - это создание нового опросника
     */
    if (typeof state.id === 'undefined') {
      state.name = '';
      state.description = '';
      state.steps = [{
        uuid: uuidv4(),
        serial: 1,
        collapsed: false,
        questions: [],
        stepCondition: [],
      }];
    }
    /**
     *
     */
    const emitData = () => {
      state.questionsErrors.rootName = state.name === '';
      // state.questionsErrors.rootDescription = state.description === '';

      const out: Partial<IInterview> = {
        name: state.name,
        description: state.description,
        steps: state.steps,
        basePrice: state.basePrice,
        prices: state.prices,
        gotErrors: Object.values(state.questionsErrors).length
          ? Object.values(state.questionsErrors).reduce((p, c) => p || c) : false,
        canDelete: state.canDelete,
        onlyMeta: state.onlyMeta,
      };

      if (state.globalTotalLimitActive) {
        out.totalLimit = state.totalLimit;
      }

      emit('update:modelValue', out);
    };
    /**
     * Метод очищаем все условия в опроснике
     */
    const clearConditions = () => {
      state.steps = [...state.steps.map((s: IStep) => ({
        ...s,
        stepCondition: [],
        questions: s.questions.map((q: IQuestionType) => ({
          ...q,
          conditions: [],
        })),
      }))];
    };
    /**
     * Метод отображаем модальное окно с выбором типа вопроса для добавления
     *
     * @param stepId
     */
    const openAddQuestionModal = (stepId: string) => {
      state.addQuestionModal.currentStepId = stepId;
      state.addQuestionModal.visible = true;
    };
    /**
     * Метод пересчитывает порядковые номера (используется после добавления/удаления)
     */
    const recalculateSerials = () => {
      let questionsCount = 0;
      state.steps = [...state.steps.map((step: IStep, stepIndex: number) => ({
        ...step,
        serial: stepIndex + 1,
        questions: step.questions ? step.questions.map((question: IQuestionType, questionIndex: number) => {
          questionsCount += 1;
          question.serial = questionsCount;
          question.stepSerial = questionIndex + 1;

          return question;
        }) : [],
        canHaveConditions: (step.serial > 1 && questionsCount > 0 && step.questions.length > 0),
      }))];

      emitData();
    };
    /**
     * Метод сортирует шаги опросника (используется после методов moveStepUp и moveStepDown)
     */
    const sortSteps = () => {
      state.steps.sort((a: IStep, b: IStep) => a.serial - b.serial);

      emitData();
    };
    /**
     * Метод добавляет в опросник новый шаг
     */
    const addStep = () => {
      state.steps = [
        ...state.steps,
        {
          uuid: uuidv4(),
          serial: state.steps.length + 1,
          collapsed: false,
          questions: [],
          stepCondition: [],
        },
      ];
      recalculateSerials();
      emitData();
    };
    /**
     * Метод добавляет новый вопрос в указанный шаг опросника
     */
    const addQuestion = () => {
      state.steps.map((step: IStep) => {
        if (step.uuid === state.addQuestionModal.currentStepId) {
          step.questions = [
            ...step.questions,
            {
              uuid: uuidv4(),
              serial: step.questions.length + 1,
              collapsed: false,
              type: state.addQuestionModal.selectedType,
              question: '',
              description: '',
              required: true,
              conditions: [],
            } as unknown as IQuestionType,
          ];
        }

        return step;
      });
      state.addQuestionModal.visible = false;
      recalculateSerials();
      emitData();
    };
    /**
     * Метод удаляет выбранный вопрос из указанного шага опросника
     *
     * @param stepId
     * @param questionId
     */
    const deleteQuestion = (stepId: string, questionId: string) => {
      magnerConfirm({
        message: customT(translate('gaz.interview.layout.confirm_question.message')),
        title: customT(translate('gaz.interview.layout.confirm_question.title')),
        confirmButtonText: customT(translate('gaz.interview.layout.confirm_question.confirm')),
        cancelButtonText: customT(translate('gaz.interview.layout.confirm_question.cancel')),
        type: 'warning',
      }).then(() => {
        const filteredSteps: IStep[] = [];

        state.steps.map((step: IStep) => {
          if (step.uuid === stepId) {
            step.questions = [...step.questions.filter((question: IQuestion) => question.uuid !== questionId)];

            if (step.questions.length > 0) {
              filteredSteps.push(step);
            }
          } else {
            filteredSteps.push(step);
          }

          return step;
        });

        state.steps = [...filteredSteps];

        recalculateSerials();
      });
    };
    /**
     * Метод удаляет весь шаг
     *
     * @param stepId
     */
    const deleteStep = (stepId: string) => {
      magnerConfirm({
        message: customT(translate('gaz.interview.layout.confirm_step.message')),
        title: customT(translate('gaz.interview.layout.confirm_step.title')),
        confirmButtonText: customT(translate('gaz.interview.layout.confirm_step.confirm')),
        cancelButtonText: customT(translate('gaz.interview.layout.confirm_step.cancel')),
        type: 'warning',
      }).then(() => {
        state.steps = [...state.steps.filter((step: IStep) => step.uuid !== stepId)];

        recalculateSerials();
      });
    };
    /**
     * Метод перемещает выбранный шаг выше по списку
     *
     * @param stepId
     */
    const moveStepUp = (stepId: string) => {
      const f = () => {
        state.steps.reduce((prev: IStep, current: IStep) => {
          if (current.uuid === stepId) {
            const tmpSerial = prev.serial;
            prev.serial = current.serial;
            current.serial = tmpSerial;
          }
          return current;
        });

        sortSteps();
      };

      if (interviewHasConditions.value) {
        magnerConfirm({
          message: customT(translate('gaz.interview.layout.confirm_move.message')),
          title: customT(translate('gaz.interview.layout.confirm_move.title')),
          confirmButtonText: customT(translate('gaz.interview.layout.confirm_move.confirm')),
          cancelButtonText: customT(translate('gaz.interview.layout.confirm_move.cancel')),
          type: 'warning',
        }).then(() => {
          clearConditions();
          f();
        });
      } else {
        f();
      }
    };
    /**
     * Метод перемещает указанный шаг ниже по списке
     * @param stepId
     */
    const moveStepDown = (stepId: string) => {
      const f = () => {
        state.steps.reduce((prev: IStep, current: IStep) => {
          if (prev.uuid === stepId) {
            const tmpSerial = prev.serial;
            prev.serial = current.serial;
            current.serial = tmpSerial;
          }
          return current;
        });

        sortSteps();
      };

      if (interviewHasConditions.value) {
        magnerConfirm({
          message: customT(translate('gaz.interview.layout.confirm_move.message')),
          title: customT(translate('gaz.interview.layout.confirm_move.title')),
          confirmButtonText: customT(translate('gaz.interview.layout.confirm_move.confirm')),
          cancelButtonText: customT(translate('gaz.interview.layout.confirm_move.cancel')),
          type: 'warning',
        }).then(() => {
          clearConditions();
          f();
        });
      } else {
        f();
      }
    };
    /**
     * Метод перемещает указанный вопрос выше по списку
     *
     * @param stepId
     * @param questionId
     */
    const moveQuestionUp = (stepId: string, questionId: string) => {
      const f = () => {
        state.steps.map((step: IStep) => {
          if (step.uuid === stepId) {
            step.questions.reduce((prev: IQuestionType, current: IQuestionType) => {
              if (current.uuid === questionId) {
                const tmpSerial = prev.serial;
                prev.serial = current.serial;
                current.serial = tmpSerial;
              }
              return current;
            });
            step.questions.sort((a: IQuestionType, b: IQuestionType) => a.serial - b.serial);
          }

          return step;
        });

        recalculateSerials();
      };

      if (interviewHasConditions.value) {
        magnerConfirm({
          message: customT(translate('gaz.interview.layout.confirm_move_question.message')),
          title: customT(translate('gaz.interview.layout.confirm_move_question.title')),
          confirmButtonText: customT(translate('gaz.interview.layout.confirm_move_question.confirm')),
          cancelButtonText: customT(translate('gaz.interview.layout.confirm_move_question.cancel')),
          type: 'warning',
        }).then(() => {
          clearConditions();
          f();
        });
      } else {
        f();
      }
    };
    /**
     * Метод перемещает указанный вопрос ниже по списку
     *
     * @param stepId
     * @param questionId
     */
    const moveQuestionDown = (stepId: string, questionId: string) => {
      const f = () => {
        state.steps.map((step: IStep) => {
          if (step.uuid === stepId) {
            step.questions.reduce((prev: IQuestionType, current: IQuestionType) => {
              if (prev.uuid === questionId) {
                const tmpSerial = prev.serial;
                prev.serial = current.serial;
                current.serial = tmpSerial;
              }
              return current;
            });
            step.questions.sort((a: IQuestionType, b: IQuestionType) => a.serial - b.serial);
          }

          return step;
        });

        recalculateSerials();
      };

      if (interviewHasConditions.value) {
        magnerConfirm({
          message: customT(translate('gaz.interview.layout.confirm_move_question.message')),
          title: customT(translate('gaz.interview.layout.confirm_move_question.title')),
          confirmButtonText: customT(translate('gaz.interview.layout.confirm_move_question.confirm')),
          cancelButtonText: customT(translate('gaz.interview.layout.confirm_move_question.cancel')),
          type: 'warning',
        }).then(() => {
          clearConditions();
          f();
        });
      } else {
        f();
      }
    };
    /**
     * Метод обрабатывает событие изменения данных в вопросе
     *
     * @param data
     * @param stepId
     * @param questionId
     */
    const questionDataUpdated = (data: IQuestion, stepId: string, questionId: string) => {
      state.steps.map((step: IStep) => {
        if (stepId === step.uuid) {
          step.questions = [
            ...step.questions.map((question: IQuestionType) => {
              if (questionId === question.uuid) {
                return { ...data, conditions: question.conditions } as IQuestionType;
              }

              return question;
            }),
          ];
        }

        return step;
      });

      emitData();
    };
    /**
     * Метод раскрывает/скрывает выбранный шаг
     * @param stepId
     */
    const toggleCollapsed = (stepId: string) => {
      state.steps.map((item: IStep) => {
        if (item.uuid === stepId) {
          if (!item.collapsed) {
            item.collapsed = false;
          }
          item.collapsed = !item.collapsed;
        }

        return item;
      });
    };
    /**
     * Метод добавляет условия отображения вопроса
     * @param stepId
     * @param questionId
     */
    const questionAddConditions = (stepId: string, questionId: string) => {
      state.steps.map((step: IStep) => {
        if (stepId === step.uuid) {
          step.questions = [
            ...step.questions.map((question: IQuestionType) => {
              if (questionId === question.uuid) {
                return {
                  ...question,
                  conditions: [
                    [{
                      id: uuidv4(),
                      parentQuestionUuid: '',
                      serial: 1,
                      operator: '',
                      value: '',
                    }],
                  ],
                };
              }

              return question;
            }),
          ];
        }

        return step;
      });

      emitData();
    };
    /**
     * Метод добавляет условия отображения шага
     * @param step
     */
    const stepAddCondition = (step: IStep) => {
      step.stepCondition = [[{
        id: uuidv4(),
        parentQuestionUuid: '',
        serial: 1,
        operator: '',
        value: '',
      }]];
    };
    /**
     * Метод обрабатывает обновление условий
     * @param value
     * @param question
     */
    const conditionsDataUpdated = (value: IConditionBlock[], question: IQuestion) => {
      question.conditions = value;

      emitData();
    };
    /**
     * Метод обрабатывает обновление условий для шага
     * @param value
     * @param step
     */
    const stepConditionDataUpdated = (value: IConditionBlock[], step: IStep) => {
      step.stepCondition = value;

      emitData();
    };
    /**
     *
     * @param value
     * @param questionId
     */
    const questionGotErrors = (value: boolean, questionId: string) => {
      state.questionsErrors[questionId] = value;
    };
    /**
     *
     */
    const openAddPriceModal = () => {
      state.addPriceModal.visible = true;
    };
    /**
     * Добавить слагаемое стоимости
     */
    const addPrice = () => {
      state.prices.push({
        id: uuidv4() as string,
        name: `${customT(translate('gaz.interview.layout.price'))} - ${state.prices.length + 1}`,
        price: 0,
        questionId: '',
      });
      emitData();
    };
    /**
     *
     */
    const deletePrice = (id: string) => {
      state.prices = [...state.prices.filter((p) => p.id !== id)];
      emitData();
    };
    /**
     * Список вопросов, в которых есть лимиты (для цены) и обязательные
     */
    const questionsWithLimits = computed(() => {
      const list: {questionId: string, name: string}[] = [];
      state.steps.map((s: IStep) => {
        s.questions.map((q: IQuestion) => {
          if (
            // TODO: в МП пока не обрабатывается вариант с вопросом типа Список
            (/* q.type === QUESTION_TYPE.LIST || */ q.type === QUESTION_TYPE.PLAIN_ANSWER)
            && (q as IListQuestion).dataType === DATA_TYPE.NUMBER
            // && q.required
          ) {
            list.push({
              questionId: q.uuid,
              name: `${customT(translate('gaz.interview.layout.step'))} ${s.serial} - ${customT(translate('gaz.interview.layout.question'))} ${q.stepSerial}: ${q.question}`,
            });
          }

          return q;
        });
        return s;
      });

      return list;
    });
    /**
     *
     * @param value
     */
    const changeInterviewPayable = (value: boolean) => {
      if (value) {
        state.isEventPayable = value;
        emitData();
        return;
      }
      ElMessageBox.confirm(
        customT(translate('gaz.interview.layout.confirm_price.message')),
        customT(translate('gaz.interview.layout.confirm_price.title')),
        {
          confirmButtonText: customT(translate('gaz.interview.layout.confirm_price.confirm')),
          cancelButtonText: customT(translate('gaz.interview.layout.confirm_price.cancel')),
          type: 'warning',
        },
      )
        .then(() => {
          state.isEventPayable = value;
          state.prices = [];
          emitData();
        });
    };

    onMounted(() => {
      recalculateSerials();
    });

    return {
      state,

      QUESTION_TYPE_OPTIONS,

      openAddQuestionModal,
      addQuestion,
      addStep,
      deleteQuestion,
      deleteStep,
      moveStepUp,
      moveStepDown,
      moveQuestionUp,
      moveQuestionDown,
      questionDataUpdated,
      toggleCollapsed,
      questionAddConditions,
      questionGotErrors,
      stepAddCondition,
      conditionsDataUpdated,
      stepConditionDataUpdated,
      openAddPriceModal,
      addPrice,
      deletePrice,
      changeInterviewPayable,
      emitData,
      translateText: (code: string) => customT(translate(code)),

      questionsWithLimits,

      ArrowUpBold,
      ArrowDownBold,
      Delete,
      Hide,
      View,
      QuestionFilled,
      Warning,
      Plus,
    };
  },
});
</script>

<style lang="scss" src="/src/features/settings/interviews/assets/scss/layout.scss"/>
