import loginPageConfig from 'features/login/login';
import residentsConfig from 'features/residents/residents/gaz';
import residentConfig from 'features/residents/resident/gaz';
import eventConfig from 'features/events/event/gazprom';
import eventGuestsConfig from 'features/events/guests/guests/hubstr';
import eventsConfig from 'features/events/events/hubstr';
import addressConfig from 'features/addresses/address/hubstr';
import addressesConfig from 'features/addresses/addresses/hubstr';
import eventCategoriesConfig from 'features/settings/event-categories/categories/hubstr';
import clubRolesConfig from 'features/settings/club-roles/roles/hubstr';
import interviewsConfig from 'features/settings/interviews/interviews';
import interviewConfig from 'features/settings/interviews/interview';
import { translate } from 'magner';
import profileConfig from 'features/profile/profile/profile';
import suggestionsConfig from 'features/profile/safety/suggestions/table';
import breakingConfig from 'features/profile/safety/breaking/table';
import bossWordConfig from 'features/profile/bossword/bossword';
import feedbackConfig from 'features/profile/feedback/table';
import sportsSectionsConfig from 'features/profile/sports/sections/table';
import sportsTourneysConfig from 'features/profile/sports/tourney/table';
import sportsGtoConfig from 'features/profile/sports/gto/gto';
import blanksConfig from 'features/profile/blanks/blanks';
import blanksAdminConfig from 'features/profile/blanks_admin/blanks';
import loyaltyMainConfig from 'features/profile/loyalty/main/card';
import loyaltyCategoriesConfig from 'features/profile/loyalty/categories/card';
import faqConfig from 'features/profile/faq/card';
import complaintsConfig from 'features/settings/complaints/complaints/hubstr';
import bansConfig from 'features/settings/bans/bans/hubstr';
import linkGroupsConfig from 'features/settings/link-groups/groups/hubstr';
import reportsUserActivityConfig from 'features/reports/user-activity/table';
import reportsEventsConfig from 'features/reports/events/table';
import reportsCertificateConfig from 'features/reports/certificate/table';
import reportsPartnersConfig from 'features/reports/partners/table';
import linksConfig from 'features/settings/link-groups/links/hubstr';
import mobileEditorConfig from 'features/settings/mobile-editor/mobile-editor';
import authListConfig from 'features/settings/authorization/list';
import preferenceConfig from 'features/settings/preferences/table/gaz';
import changeLogConfig from 'features/change-log/gaz/gaz';
import appearanceConfig from 'features/settings/appearance/appearance';
import notificationTable from 'features/settings/notification/table/hubstr';
import notificationTableConfig from 'features/settings/notification/list/hubstr';
import { APP_SETTINGS_EDITOR_ON } from '~/constants';

export const gazRouterConfig = {
  global: {
    suffixUrl: 'admin',
    homeNoAuthName: 'login',
    homeHasAuthName: 'residents',
  },

  routes: [
    {
      type: 'custom',
      route: {
        name: 'vimeo',
        path: '/domen/video/:id',
        component: () => import('features/vimeo/vimeo.vue'),
        roles: false,
      },
    },
    {
      type: 'custom',
      route: {
        name: 'home',
        path: '/',
        component: () => import('configs/empty.vue'),
        roles: false,
      },
    },

    {
      type: 'preset',
      route: {
        path: '/login',
        name: 'login',
      },
      preset: {
        preset: 'login',
        config: loginPageConfig,
      },
    },

    {
      type: 'layout',
      layout: {
        layout: 'main',
        name: '',
        path: '/',
        props: {
          headerIcon: () => import('assets/gaz/icons/logo-not-collapsed.svg'),
          headerCollapsedIcon: () => import('assets/gaz/icons/logo-collapsed.svg'),

          sidebarGroups: [
            {
              name: 'settings-group',
              icon: () => import('assets/icons/settings.svg'),
              title: translate('hubstr.login.sidebar.group_title'),
              routes: [
                'addresses',
                'event-categories',
                'club-roles',
                'club-in-club',
                'partners',
                'community-managers',
              ],
            },
            {
              name: 'system-group',
              icon: () => import('assets/icons/system.svg'),
              title: translate('hubstr.system.sidebar.group_title'),
              routes: [
                'link-groups',
                'mobile-editor',
                'auth-list',
                'preferences',
                'appearance',
                'notifications',
              ],
            },
            {
              name: 'profile-group',
              icon: () => import('assets/icons/profile.svg'),
              title: translate('gaz.profile.sidebar.title'),
              routes: [
                'profile',
                'suggestions',
                'loyalty',
                'loyalty-categories',
                'sports-sections',
                'feedback',
                'boss-word',
                'faq',
                'blanks',
                'blanks-admin',
              ],
            },
            {
              name: 'reports-group',
              icon: () => import('assets/icons/reports.svg'),
              title: translate('hubstr.reports.sidebar.title'),
              routes: [
                'report-user-activity',
                'report-events',
                'report-certificate',
                'report-partners',
              ],
            },
          ],
        },
        routes: [
          /** Residents - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: residentsConfig,
            },
            route: {
              name: 'residents',
              path: '/residents',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.resident.sidebar.table_title'),
            },
          },
          /** Residents - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: residentConfig,
            },
            route: {
              name: 'resident',
              path: '/residents/:id',
              link: '/residents/new',
              roles: true,
              visible: false,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.resident.sidebar.card_title'),
            },
          },

          /** Events - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventsConfig,
            },
            route: {
              name: 'events',
              path: '/events',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/map-pin.svg'),
              title: translate('hubstr.event.sidebar.table_title'),
            },
          },
          /** Events - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: eventConfig,
            },
            route: {
              name: 'event',
              path: '/events/:id',
              link: '/events/new',
              roles: true,
              visible: false,
              title: translate('hubstr.event.sidebar.card_title'),
            },
          },
          /** Interviews - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: interviewsConfig,
            },
            route: {
              name: 'interviews',
              path: '/settings/interviews',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/polls.svg'),
              title: translate('gaz.interview.sidebar.table_title'),
            },
          },
          /** Interviews - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: interviewConfig,
            },
            route: {
              name: 'interview',
              path: '/settings/interviews/:id',
              link: '/settings/interviews/new',
              roles: true,
              visible: false,
              title: translate('gaz.interview.sidebar.card_title'),
            },
          },
          /** Events guests - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventGuestsConfig,
            },
            route: {
              name: 'event-guests',
              path: '/events/:id/guests',
              roles: true,
              visible: false,
              title: translate('hubstr.event.guests.sidebar.table_title'),
            },
          },

          /** Profile - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: profileConfig,
            },
            route: {
              name: 'profile',
              path: '/profile',
              roles: true,
              visible: true,
              title: translate('gaz.profile.sidebar.table_title'),
            },
          },
          /** Suggestions - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: suggestionsConfig,
            },
            route: {
              name: 'suggestions',
              path: '/suggestions',
              roles: true,
              visible: true,
              title: translate('gaz.safety.sidebar.table_title'),
            },
          },
          /** Sports sections - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: sportsSectionsConfig,
            },
            route: {
              name: 'sports-sections',
              path: '/sports-sections',
              roles: true,
              visible: true,
              title: translate('gaz.sports.sections.sidebar.title'),
            },
          },
          /** Sports tourneys - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: sportsTourneysConfig,
            },
            route: {
              name: 'sports-tourney',
              path: '/sports-sections/sports-tourney',
              roles: true,
              visible: false,
            },
          },
          /** Sports Gto - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: sportsGtoConfig,
            },
            route: {
              name: 'sports-gto',
              path: '/sports-sections/sports-gto',
              roles: true,
              visible: false,
            },
          },
          /** Breaking - card */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: breakingConfig,
            },
            route: {
              name: 'breaking',
              path: '/suggestions/breaking',
              roles: true,
              visible: false,
              title: translate('gaz.safety.sidebar.table_title'),
            },
          },
          /** Feedback - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: feedbackConfig,
            },
            route: {
              name: 'feedback',
              path: '/feedback',
              roles: true,
              visible: true,
              title: translate('gaz.feedback.sidebar.table_title'),
            },
          },
          /** Blanks - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: blanksConfig,
            },
            route: {
              name: 'blanks',
              path: '/blanks',
              roles: true,
              visible: true,
              title: translate('gaz.blanks.sidebar.table_title'),
            },
          },
          /** Blanks Admin - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: blanksAdminConfig,
            },
            route: {
              name: 'blanks-admin',
              path: '/blanks-admin',
              roles: true,
              visible: true,
              title: translate('gaz.blanks_admin.sidebar.table_title'),
            },
          },
          /** Loyalty main - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: loyaltyMainConfig,
            },
            route: {
              name: 'loyalty',
              path: '/loyalty',
              roles: true,
              visible: true,
              title: translate('gaz.loyalty.sidebar.card_title'),
            },
          },
          /** Loyalty categoryes - table */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: loyaltyCategoriesConfig,
            },
            route: {
              name: 'loyalty-categories',
              path: '/loyalty/loyalty-categories',
              roles: true,
              visible: false,
            },
          },
          /** Faq - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: faqConfig,
            },
            route: {
              name: 'faq',
              path: '/faq',
              roles: true,
              visible: true,
              title: translate('gaz.faq.sidebar.menu_title'),
            },
          },

          /** Boss word - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: bossWordConfig,
            },
            route: {
              name: 'boss-word',
              path: '/boss-word',
              roles: true,
              visible: true,
              title: translate('gaz.boss_word.sidebar.card_title'),
            },
          },

          /** Addresses - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: addressesConfig,
            },
            route: {
              name: 'addresses',
              path: 'catalogs/addresses',
              roles: true,
              visible: true,
              title: translate('hubstr.address.sidebar.table_title'),
            },
          },
          /** Addresses - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: addressConfig,
            },
            route: {
              name: 'address',
              path: '/addresses/:id',
              link: '/addresses/new',
              roles: true,
              visible: false,
              title: translate('hubstr.address.sidebar.card_title'),
            },
          },

          /** Reports block */
          /** Reports - user activity */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: reportsUserActivityConfig,
            },
            route: {
              name: 'report-user-activity',
              path: '/reports/user-activity',
              roles: true,
              visible: true,
              title: translate('hubstr.reports.sidebar.user_activity_title'),
            },
          },
          /** Reports - events */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: reportsEventsConfig,
            },
            route: {
              name: 'report-events',
              path: '/reports/events',
              roles: true,
              visible: true,
              title: translate('hubstr.reports.sidebar.events_title'),
            },
          },
          /** Reports - certificate */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: reportsCertificateConfig,
            },
            route: {
              name: 'report-certificate',
              path: '/reports/certificate',
              roles: true,
              visible: true,
              title: translate('hubstr.reports.sidebar.certificate_title'),
            },
          },
          /** Reports - partners */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: reportsPartnersConfig,
            },
            route: {
              name: 'report-partners',
              path: '/reports/partners',
              roles: true,
              visible: true,
              title: translate('hubstr.reports.sidebar.partners_title'),
            },
          },

          /** Settings block */
          /** Edit mobile profile */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: mobileEditorConfig,
            },
            route: {
              name: 'mobile-editor',
              path: '/settings/mobile-editor',
              roles: true,
              visible: APP_SETTINGS_EDITOR_ON,
              title: translate('hubstr.mobile_profile.sidebar.title'),
            },
          },
          /** Event categories - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventCategoriesConfig,
            },
            route: {
              name: 'event-categories',
              path: '/catalogs/event-categories',
              roles: true,
              visible: true,
              title: translate('hubstr.event_category.sidebar.table_title'),
            },
          },
          /** Club roles - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: clubRolesConfig,
            },
            route: {
              name: 'club-roles',
              path: '/catalogs/club-roles',
              roles: true,
              visible: true,
              title: translate('hubstr.club_role.sidebar.table_title'),
            },
          },
          /** Link groups - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: linkGroupsConfig,
            },
            route: {
              name: 'link-groups',
              path: '/settings/link-groups',
              roles: true,
              visible: true,
              title: translate('hubstr.link_groups.sidebar.table_title'),
            },
          },
          /** Links - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: linksConfig,
            },
            route: {
              name: 'links',
              path: '/settings/link-groups/:id',
              roles: true,
              visible: false,
              title: translate('hubstr.link_groups.sidebar.table_title'),
            },
          },
          /** Auth list - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: authListConfig,
            },
            route: {
              name: 'auth-list',
              path: '/settings/auth-list',
              roles: true,
              visible: true,
              title: translate('hubstr.auth.sidebar.table_title'),
            },
          },
          /** Complaints - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: complaintsConfig,
            },
            route: {
              name: 'complaints',
              path: '/complaints',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.complaints.sidebar.table_title'),
            },
          },
          /** Bans - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: bansConfig,
            },
            route: {
              name: 'bans',
              path: '/bans',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.bans.sidebar.table_title'),
            },
          },
          /** Preferences - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: preferenceConfig,
            },
            route: {
              name: 'preferences',
              path: '/settings/preferences',
              roles: true,
              visible: true,
              title: translate('hubstr.preferences.sidebar.title'),
            },
          },
          /** Appearance */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: appearanceConfig,
            },
            route: {
              name: 'appearance',
              path: '/settings/appearance',
              roles: true,
              visible: true,
              title: translate('hubstr.appearance.sidebar.title'),
            },
          },
          /** Notifications */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: notificationTableConfig,
            },
            route: {
              name: 'notifications',
              path: '/settings/notifications',
              roles: true,
              visible: true,
              title: translate('hubstr.notification.sidebar.table_title'),
            },
          },
          /** Change Log */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: changeLogConfig,
            },
            route: {
              name: 'change-log',
              path: '/change-log',
              roles: true,
              visible: false,
              title: 'change-log',
            },
          },
        ],
      },
    },

    {
      type: 'preset',
      route: {
        path: '/:pathMatch(.*)*',
        name: 'error',
      },
      preset: {
        preset: '404',
        config: {},
      },
    },
  ],
};
