import { action, tablePageController, translate } from 'magner';
import { reportsRead, downloadReport } from 'features/reports/certificate/requests';
import { searchResidents } from 'features/settings/notification/requests/hubstr';
import ResidentLink from 'features/reports/user-activity/residentLink.vue';
import { autocompleteDepartment } from 'features/residents/requests/hubstr';
import { subtractMonthDate } from '~/utils/format-date';

const reportsCertificateConfig = tablePageController<any>({
  header: {
    title: translate('hubstr.reports.table.certificate.title'),
  },
  request: reportsRead,
  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: translate('hubstr.reports.table.not_found'),
    columns: [
      {
        prop: 'userId',
        label: translate('hubstr.reports.table.certificate.userId'),
        width: 150,
        view: {
          type: 'custom',
          component: () => ResidentLink,
        },
      },
      {
        prop: 'certificateView',
        label: translate('hubstr.reports.table.certificate.certificateView'),
      },
      {
        prop: 'certificateShared',
        label: translate('hubstr.reports.table.certificate.certificateShared'),
      },
    ],
  },
  filters: {
    actions: [{
      type: 'action',
      props: {
        text: translate('hubstr.reports.table.download'),
        type: 'primary',
        hidden: (data) => !data.state.dateStart.length || !data.state.department.length,
      },
      // @ts-ignore
      action: action(async ({ data }) => {
        if (data.filters.department.length && data.filters.dateStart.length) {
          await downloadReport({ ...data, format: 'xlsx' });
        }
      }),
    }],

    saveToLocalStorage: true,

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersInSeparatePanel: false,
    filtersData: {
      department: '',
      users: '',
      dateStart: subtractMonthDate(),
      dateEnd: '',
    },
    sort: {},

    layout: [
      {
        type: 'select',
        name: 'department',
        options: [],
        label: translate('hubstr.reports.filters.department.label'),
        props: {
          required: true,
          valueKey: 'id',
          labelKey: 'name',
          filterable: true,
          remote: true,
          remoteMethod: autocompleteDepartment,
          placeholder: translate('hubstr.reports.filters.department.placeholder'),
          multiple: true,
          collapseTags: true,
          clearable: true,
        },
        validation: [{
          type: 'empty-required' as 'empty',
          trigger: 'change',
        }],
      },
      {
        type: 'select',
        name: 'users',
        dataType: 'array',
        options: [],
        label: translate('hubstr.reports.filters.users.label'),
        props: {
          disabled: (data: { state: {department: string} }) => !data.state.department.length,
          multiple: true,
          filterable: true,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: searchResidents,
          placeholder: translate('hubstr.reports.filters.users.placeholder'),
        },
      },
      {
        type: 'datetime',
        name: 'dateStart',
        dataType: 'date',
        label: translate('hubstr.reports.filters.dateStart.label'),
        props: {
          required: true,
          type: 'date',
          format: 'DD.MM.YYYY',
          placeholder: translate('hubstr.reports.filters.dateStart.placeholder'),
        },
        validation: [{
          type: 'empty-required' as 'empty',
          trigger: 'change',
        }],
      },
      {
        type: 'datetime',
        name: 'dateEnd',
        dataType: 'date',
        label: translate('hubstr.reports.filters.dateEnd.label'),
        hint: translate('hubstr.reports.filters.dateEnd.hint'),
        props: {
          type: 'date',
          format: 'DD.MM.YYYY',
          placeholder: translate('hubstr.reports.filters.dateEnd.placeholder'),
        },
      },
    ],
  },
});

export default reportsCertificateConfig;
