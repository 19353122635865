import { translate } from 'magner';
import { autocompleteDepartment, residentSearch } from 'features/residents/requests/hubstr';
import ruCustom from 'configs/translation/ru';
import inlineText from 'features/residents/resident/components/inlineText.vue';
import unpackField from '~/utils/unpack-field';
import {APP_MILLIARD, ROLE, SUB_DOMEN} from '~/constants';

export interface MetaHubstrField {
  type: string,
  label: string,
  name: string,
  props: Object,
  layout: Object
}
export interface MetaHubstrFieldsGroup {
  title: string;
  fields: MetaHubstrField[];
}

const hubstrConfig = (groups: MetaHubstrFieldsGroup[]) => {
  const layout = {
    type: 'row',
    props: {},
    layout: [
      /** Left column */
      {
        type: 'column',
        props: {
          span: 12,
          xs: 24,
          styles: { 'padding-right': '12px' },
        },
        layout: [
          /** Lastname and Firstname */
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [unpackField({
                  type: 'input',
                  required: false,
                  name: 'currentUserId',
                  label: '',
                  placeholder: '',
                  propsHidden: true,
                  propsDisabled: true,
                }),
                  unpackField({
                  type: 'input',
                  required: false,
                  name: 'id',
                  label: '',
                  placeholder: '',
                  propsHidden: true,
                  propsDisabled: true,
                }),
                  unpackField({
                  type: 'input',
                  required: true,
                  name: 'lastName',
                  label: translate('hubstr.resident.form.last_name.label'),
                  placeholder: translate('hubstr.resident.form.last_name.placeholder'),
                  validation: [{
                    type: 'empty-required',
                    trigger: 'blur',
                  }],
                  // special
                  dataType: 'string',
                })],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [unpackField({
                  type: 'input',
                  required: true,
                  name: 'firstName',
                  label: translate('hubstr.resident.form.first_name.label'),
                  placeholder: translate('hubstr.resident.form.first_name.placeholder'),
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'blur',
                  }],
                  // special
                  dataType: 'string',
                })],
              },
            ],
          },
          /** Phone, Email */
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [
                  unpackField({
                    type: 'input',
                    name: 'email',
                    label: translate('hubstr.resident.form.email.label'),
                    props: {
                      type: 'email',
                      placeholder: translate('hubstr.resident.form.email.placeholder'),
                    },
                    validation: [
                      {
                        type: 'email-required' as 'email',
                        trigger: 'blur',
                      },
                    ],
                  })],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [
                  unpackField({
                    type: 'input',
                    required: true,
                    name: 'phone',
                    label: translate('hubstr.resident.form.phone.label'),
                    placeholder: SUB_DOMEN === APP_MILLIARD ? translate('hubstr.resident.form.phone.placeholder') : '+7-123-456-78-90',
                    validation: [{
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    }, {
                      type: 'phone',
                      trigger: 'blur',
                    }],
                    // special
                    dataType: 'string',
                    propsMask: {
                      mask: '+#*',
                    },
                  }),
                ],
              },
            ],
          },
          /** Status */
          unpackField({
            type: 'select',
            required: false,
            name: 'status',
            label: translate('hubstr.resident.form.status.label'),
            placeholder: '',
            propsHiddenFunction: '!data.state.status',
            // special
            remoteMethodSettings: {
              url: '/api/admin/user/statuses',
              parameter: 'statuses',
              type: 'select',
              valueKey: 'code',
              labelKey: 'title',
            },
          }),
          /** Password, role */
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'input',
                  required: false,
                  name: 'roleCurrent',
                  label: '',
                  placeholder: '',
                  propsHidden: true,
                  propsDisabled: true,
                },
                {
                  type: 'input',
                  required: false,
                  name: 'rolesList',
                  label: '',
                  placeholder: '',
                  propsHidden: true,
                  propsDisabled: true,
                },
                {
                  type: 'select',
                  required: true,
                  name: 'roles',
                  label: translate('hubstr.resident.form.roles.label'),
                  placeholder: 'Администратор',
                  hint: 'Поле влияет на доступ в административную панель. '
                        + 'Также регулирует некоторые права внутри мобильного приложения. '
                        + 'Например, роль "Администратор" дает право на удаление чужих комментариев',
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                  propsDisabled: ({ role, state }) => state.id === state.currentUserId && role === ROLE.ADMIN && state.roleCurrent.name === ROLE.ADMIN,
                  // special
                  remoteMethodSettings: {
                    url: '/api/admin/role/list',
                    parameter: 'data',
                    valueKey: 'id',
                    labelKey: 'label',
                  },
                }].map((f) => unpackField(f)),
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [
                  /** Password not required */
                  {
                    type: 'input',
                    required: false,
                    name: 'password',
                    label: translate('hubstr.resident.form.password.label'),
                    placeholder: '',
                    propsHiddenFunction: `!data.state.roles
                      || data.state.rolesList[data.state.roles] !== 'ROLE_ADMIN'
                      || (data.state.rolesList[data.state.roles] === 'ROLE_ADMIN' && data.state.roles !== data.state.roleCurrent.id)`,
                  },
                  /** Password required */
                  {
                    type: 'input',
                    required: true,
                    name: 'password',
                    label: translate('hubstr.resident.form.password.label'),
                    placeholder: '',
                    propsHiddenFunction: `!data.state.roles
                      || data.state.rolesList[data.state.roles] !== 'ROLE_ADMIN'
                      || (data.state.rolesList[data.state.roles] === 'ROLE_ADMIN' && data.state.roles === data.state.roleCurrent.id)`,
                    validation: [{
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    }],
                  },
                ].map((f) => unpackField(f)),
              },
            ],
          },
          /** Avatar */
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              /** Avatar */
              {
                type: 'column',
                props: {
                  span: 8,
                  xs: 24,
                },
                layout: [
                  unpackField({
                    type: 'dropzone',
                    required: false,
                    name: 'avatar',
                    label: translate('hubstr.resident.form.avatar.label'),
                    placeholder: '',
                    // special
                    formats: ['jpg', 'png', 'jpeg', 'heic'],
                    valueKey: 'id',
                    srcKey: 'thumb_100',
                    removable: true,
                  })],
              },
              /** Entry */
              {
                type: 'column',
                props: {
                  span: 16,
                  xs: 24,
                  styles: { 'padding-left': '12px' },
                },
                layout: [
                  /** Badge */
                  {
                    type: 'textarea',
                    required: false,
                    name: 'userBadge',
                    label: translate('hubstr.resident.form.badge.label'),
                    placeholder: translate('hubstr.resident.form.badge.placeholder'),
                    validation: [{
                      type: 'max-length-150' as 'empty',
                      trigger: 'change',
                    }],
                    // special
                    rows: 2,
                  },
                  /** Entry */
                  {
                    type: 'datetime',
                    required: false,
                    name: 'dateOfEntry',
                    label: translate('hubstr.resident.form.entry.label'),
                    placeholder: '15.10.2021',
                    // special
                    dataType: 'date',
                    propsFormat: 'DD.MM.YYYY',
                    propsType: 'date',
                  },
                ].map((f) => unpackField(f)),
              },
            ],
          },
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              /** Recommendation User */
              {
                type: 'column',
                props: {},
                layout: [unpackField({
                  type: 'select',
                  name: 'recommendationUser',
                  label: translate('hubstr.resident.form.recommendation_user.label'),
                  placeholder: translate('hubstr.resident.form.recommendation_user.placeholder'),
                  // special
                  remoteMethodSettings: {
                    url: residentSearch,
                    parameter: 'list',
                    valueKey: 'id',
                    labelKey: 'name',
                    filterable: true,
                    clearable: true,
                  },
                })],
              },
              /** Forum group */
              {
                type: 'column',
                props: {},
                layout: [unpackField({
                  type: 'input',
                  required: false,
                  name: 'userFormGroup',
                  label: translate('hubstr.resident.form.forum_group.label'),
                  placeholder: translate('hubstr.resident.form.forum_group.placeholder'),
                  // special
                  dataType: 'string',
                })],
              },
            ],
          },
          /** Role */
          {
            type: 'column',
            props: {},
            layout: [unpackField({
              type: 'select',
              required: false,
              name: 'positionTags',
              label: translate('hubstr.resident.form.position_tag.label'),
              placeholder: translate('hubstr.resident.form.position_tag.placeholder'),
              // special
              remoteMethodSettings: {
                url: '/api/admin/user/position/list',
                parameter: 'list',
                valueKey: 'id',
                labelKey: 'name',
                multiple: true,
              },
            })],
          },
          /** Department */
          {
            type: 'column',
            props: {},
            layout: [unpackField({
              type: 'select',
              required: true,
              name: 'department',
              label: translate('hubstr.resident.form.department.label'),
              placeholder: translate('hubstr.resident.form.department.placeholder'),
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
              // special
              remoteMethodSettings: {
                url: autocompleteDepartment,
                parameter: 'departments',
                valueKey: 'id',
                labelKey: 'name',
                filterable: true,
              },
            })],
          },
          /** City and birthday */
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [
                  unpackField({
                    type: 'select',
                    required: false,
                    name: 'userCity',
                    label: translate('hubstr.resident.form.city.label'),
                    placeholder: translate('hubstr.resident.form.city.placeholder'),
                    // special
                    remoteMethodSettings: {
                      url: '/api/form/autocomplete',
                      parameter: 'result',
                      type: 'userCity',
                      multiple: true,
                      filterable: true,
                    },
                  }),
                ],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [
                  unpackField({
                    type: 'datetime',
                    required: false,
                    name: 'birthday',
                    label: translate('hubstr.resident.form.birthday.label'),
                    placeholder: translate('hubstr.resident.form.birthday.placeholder'),
                    propsType: 'date',
                    dataType: 'date',
                    propsFormat: 'DD.MM.YYYY',
                    validation: [{
                      type: 'birthday' as 'empty',
                      trigger: 'blur',
                    }],
                  }),
                ],
              },
            ],
          },
          /** Sex */
          unpackField({
            type: 'radio',
            required: false,
            name: 'sex',
            label: translate('hubstr.resident.form.gender.label'),
            placeholder: '',
            options: [
              {
                label: translate('hubstr.resident.form.gender.option_m'),
                value: true,
              },
              {
                label: translate('hubstr.resident.form.gender.option_f'),
                value: false,
              },
            ],
          }),
          {
            type: 'column',
            title: 'Контактная информация',
            props: { span: 24, isPaper: true, titleType: 'heading' },
            layout: [
              /** Telegram */
              unpackField({
                type: 'input',
                required: false,
                name: 'telegram',
                label: translate('hubstr.resident.form.telegram.label'),
                placeholder: translate('hubstr.resident.form.telegram.placeholder'),
                dataType: 'string',
              }),
              /** Facebook and Instagram */
              {
                type: 'row',
                props: { elementsGrow: true },
                layout: [
                  {
                    type: 'column',
                    props: {
                      span: 12,
                      xs: 24,
                      styles: { 'padding-right': '6px' },
                    },
                    layout: [
                      unpackField({
                        type: 'input',
                        required: false,
                        name: 'facebook',
                        label: translate('hubstr.resident.form.facebook.label'),
                        placeholder: translate('hubstr.resident.form.facebook.placeholder'),
                        dataType: 'string',
                      }),
                    ],
                  },
                  {
                    type: 'column',
                    props: {
                      span: 12,
                      xs: 24,
                      styles: { 'padding-left': '6px' },
                    },
                    layout: [
                      unpackField({
                        type: 'input',
                        required: false,
                        name: 'instagram',
                        label: translate('hubstr.resident.form.instagram.label'),
                        placeholder: translate('hubstr.resident.form.instagram.placeholder'),
                        dataType: 'string',
                      }),
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'column',
            props: {
              span: 24, isPaper: true,
            },
            layout: [
              /** acceptRules */
              unpackField({
                type: 'custom',
                name: 'acceptRules',
                component: () => inlineText,
                props: {
                  label: translate('hubstr.resident.form.accept_rules.label'),
                  formatter: (value: boolean) => (value ? translate('hubstr.resident.form.booleans.yes') : translate('hubstr.resident.form.booleans.no')),
                },
              }),
              /** profileComplete */
              unpackField({
                type: 'custom',
                name: 'profileComplete',
                component: () => inlineText,
                props: {
                  label: translate('hubstr.resident.form.profile_complete.label'),
                  formatter: (value: boolean) => (value ? translate('hubstr.resident.form.booleans.yes') : translate('hubstr.resident.form.booleans.no')),
                  hint: translate('hubstr.resident.form.profile_complete.hint'),
                },
              }),
              /** createdAt */
              unpackField({
                type: 'custom',
                name: 'createdAt',
                component: () => inlineText,
                props: {
                  label: translate('hubstr.resident.form.created_at.label'),
                  formatter: (value: string) => value && new Date(value).toLocaleString("ru", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
                },
              }),
              /** updatedAt */
              unpackField({
                type: 'custom',
                name: 'updatedAt',
                component: () => inlineText,
                props: {
                  label: translate('hubstr.resident.form.updated_at.label'),
                  formatter: (value: string) => value && new Date(value).toLocaleString("ru", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
                },
              }),
              /** changePasswordAt */
              unpackField({
                type: 'custom',
                name: 'changePasswordAt',
                component: () => inlineText,
                props: {
                  label: translate('hubstr.resident.form.change_password_at.label'),
                  formatter: (value: string) => value && new Date(value).toLocaleString("ru", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
                },
              }),
              /** lastActivityAt */
              unpackField({
                type: 'custom',
                name: 'lastActivityAt',
                component: () => inlineText,
                props: {
                  label: translate('hubstr.resident.form.last_activity.label'),
                  formatter: (value: string) => value && new Date(value).toLocaleString("ru", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
                },
              }),
            ],
          },
        ],
      },
      /** Right column */
      {
        type: 'column',
        props: {
          span: 12,
          xs: 24,
          styles: { 'padding-left': '12px' },
        },
        layout: groups.map((group: MetaHubstrFieldsGroup) => ({
          type: 'column',
          title: group.title,
          props: { span: 24, isPaper: true, titleType: 'heading' },
          layout: group.fields.map((field: MetaHubstrField) => unpackField({
            ...field,
            label: (() => {
              if (!ruCustom.dynamic[field.name] || !field.label.includes('lang.')) {
                return field.label;
              }
              return translate(`dynamic.${field.name}`);
            })(),
          })),
        })),
      },
    ],
  };

  return {
    data: {
      actions: [
        {
          type: 'action',
          emits: 'cancel',
          props: {
            text: translate('hubstr.resident.form.cancel_button'),
          },
        },
        {
          type: 'action',
          emits: 'submit',
          props: {
            text: translate('hubstr.resident.form.submit_button'),
            type: 'primary',
          },
        },
        {
          type: 'action',
          emits: 'remove',
          props: {
            text: translate('hubstr.resident.form.remove_button'),
            type: 'danger',
          },
        },
      ],
      layout,
    } as any,
  };
};

export default hubstrConfig;
