const uzCustom = {
  dynamic: {} as any,
  hubstr: {
    system: {
      sidebar: {
        group_title: 'Система',
      },
    },
    address: {
      sidebar: {
        table_title: 'Манзиллар',
        card_title: 'Янги манзил',
        group_title: 'Манзил',
      },
      form: {
        title: 'Манзил',

        submit_button: 'Сақлаш',
        cancel_button: 'Орқага',
        remove_button: 'Ўчириш',

        name: { label: 'Ном', placeholder: 'Офис' },
        address: { label: 'Манзил', placeholder: 'г. Москва, ул. Красноармейская, д. 4' },
        point: { label: 'Харитадаги нуқта', placeholder: '55.797397, 37.549390' },
        description: { label: 'Тавсиф', placeholder: 'Вход находится с правого торца' },
      },
      table: {
        title: 'Манзил',
        not_found: 'Манзил топилмади.',

        address: 'Манзил',
        name: 'Ном',
        description: 'Тавсиф',
      },
      filters: {
        create_new: 'Яратмоқ',
        submit: 'Тасдиқланг',
        name: 'Ном',
      },
    },
    event: {
      sidebar: {
        table_title: 'Тадбир',
        card_title: 'Янги тадбир',
        group_title: 'Тадбир',
      },
      table: {
        title: 'Тадбир',
        not_found: 'Тадбир топилмади',

        id: 'ID',
        name: 'Ном',
        category: 'Туркум',
        date_start: 'бошланиш санаси',
        date_end: 'тугаш муддати',
        status: 'Статус',
        isPublished: 'Нашр',

        published: 'нашр этилган',
        not_published: 'нашр этилмаган',
      },
      filters: {
        create_new: 'Яратмоқ',
        submit: 'Тасдиқланг',
        name: 'Ном',
        planned_only: 'Режалаштирилган',
        categories: 'Категориялар',
        date: 'Ой',
      },
      tabs: {
        form: 'Карточка',
        guests: 'Резидентлар',
      },
      form: {
        title: 'Тадбир',
        submit_button: 'Сақлаш',
        submit_button_publish: 'Нашр қилиш',
        cancel_button: 'Орқага',
        remove_button: 'Ўчириш',

        name: {
          label: 'Ном',
          placeholder: 'Бизнес тренинг',
          hint: 'Бўшлиқларни ҳисобга олган ҳолда 128 та белгидан ошмаслиги керак',
        },
        description: {
          label: 'Ном',
          placeholder: 'Ушбу бизнес тренингда биз жамоани қандай самарали бошқаришни ўрганамиз',
        },
        date: {
          label: 'Бошланиш ва тугаш санаси',
          start_placeholder: '20.01.2022 14:00',
          end_placeholder: '21.01.2022  15:30',
        },
        image: {
          label: 'Қопқоқ',
          hint: 'Тавсия этилган ўлчам камида 800 пиксел кенглигида,'
            + '<br/>томонлар нисбатида кўринадиган майдон 2:1. Формат JPG, JPEG, PNG. Вес не более 8 Мб',
        },
        department: { label: 'Филиаллардан резидентни таклиф қилинг', placeholder: '' },
        categories: { label: 'Категории', placeholder: 'Бизнес, ходимларни бошқариш' },
        organizer: { label: 'Ташкилотчилар', placeholder: 'Иван Иванов' },
        publish: { label: 'Тадбир пости' },
        address: { label: 'Адрес', placeholder: 'г. Москва' },
        residentsLimit: {
          label: 'Фойдаланувчиларнинг максимал сони',
          placeholder: '10',
          // TODO Нужен перевод
          hint: 'Лимит не учитывается, если задан лимит в прикрепленном опроснике.',
        },
        access: {
          label: 'Кириш',
          placeholder: 'Ёпиқ',
          opt_closed: 'Ёпиқ',
          opt_half_open: 'Ярим очиқ',
          opt_open: 'очиқ',
          opt_family: 'Оилавий',
          opt_external: 'Ташқи',
        },
        type: {
          label: 'Тур',
          placeholder: 'Асосий',
          opt_main: 'Асосий',
          opt_additional: 'Қўшимча',
          opt_clubInClub: 'Клуб ичидаги клуб',
          opt_open: 'Очиқ',
        },
        isRegistrationOpen: {
          label: 'Рўйхатга олиш',
          placeholder: '',
          opt_main: 'Очиқ',
          opt_additional: 'Ёпиқ',
        },
        status: {
          label: 'Статус',
          placeholder: 'Режалаштирилган',
          opt_planned: 'Режалаштирилган',
          opt_canceled: 'Бекор қилинган',
        },
        report: { label: 'Отчет', placeholder: 'Тадбир муваффақиятли бўлиб ўтди' },
        broadcast_link: { label: 'Ҳаволани кўриш', placeholder: 'https://example.com' },
        materials_link: { label: 'Учрашув материалларига ҳавола', placeholder: 'https://example.com' },
        rating_statistics: { label: 'Баҳо статистикаси:', xls: 'XLS', csv: 'CSV' },
        poll: {
          label: 'Сўровномани бириктиринг',
          placeholder: 'Рўйхатдан сўровномани танланг',
        },
      },
      guests: {
        sidebar: {
          table_title: 'Аъзолар',
          card_title: 'Тадбир аъзолари',
        },
        statuses: {
          willGo: 'тўғри келади',
          favorite: 'Севимлиларда',
          noAnswer: 'Жавоб йўқ',
          new: 'Таклиф этилган',
          willNotGo: 'тўғри келмайди',
          read: 'Кўрилди',
        },
        table: {
          title: 'Тадбир аъзолари',
          not_found: 'Аъзолар топилмади',
          id: 'ID',
          name: 'Исм',
          surname: 'Фамилия',
          email: 'Email',
          phone: 'Телефон рақами',
          telegram: 'Телеграм',
          status: 'Статус',
        },
        filters: {
          name: 'Исм ёки фамилия',
          status: 'Статус',
        },
      },
      organizer: {
        title: {
          placeholder: 'Ҳамкор ташкилотчининг номи',
        },
        link: {
          placeholder: 'ҳавола',
        },
      },
    },
    login: {
      title: 'Кириш',
      submitButton: 'Киритиш',
      fields: {
        phone: { placeholder: '7-900-123-45-67', label: 'Телефон рақами' },
        password: { placeholder: '******', label: 'Пароль' },
      },
      sidebar: {
        group_title: 'Созламалар',
      },
    },
    resident: {
      sidebar: {
        table_title: 'Резидентлар',
        card_title: 'Янги резидент',
        group_title: 'Резидентлар',
        companies_title: 'Компаниялар',
        company_title: 'Компания',
      },
      tabs: {
        resident: 'Резидент',
        companies: 'Компаниялар',
        create: 'Яратмоқ',
      },
      table: {
        title: 'Резидентлар',
        not_found: 'Резидентлар топилмади',
        id: 'ID',
        fio: 'ФИШ',
        first_name: 'Исм',
        last_name: 'Фамилия',
        email: 'Е-мейл',
        phone: 'Телефон рақам',
        birthday: 'Туғилган кун',
        tags: 'Клубдаги ўрни',
        avatar: 'Аватар',
        status: 'Статус',
        authMethods: 'Авторизация тури',
        set_password: 'Паролни ўрнатинг',
      },
      filters: {
        create_new: 'Яратмоқ',
        submit: 'Тасдиқлаш',
        name: 'Исм ёки фамилия',
        phone: 'Телефон рақами',
        positionTags: 'Клубдаги ўрни',
        status: 'Статус',
      },
      form: {
        title: 'Резидент',
        cancel_button: 'Орқага',
        edit_button: 'Таҳрирлаш',
        submit_button: 'Сақлаш',
        remove_button: 'Ўчириш',
        confirm_delete: 'Ҳақиқатан ҳам бу резидентни ўчириб ташламоқчимисиз?',
        failed_password_change: 'Паролни янгилаш хатоси',

        about_me: 'Мен ҳақимда',
        about_business: 'Бизнес ҳақида',
        first_name: { label: 'Исм', placeholder: 'Иван' },
        last_name: { label: 'Фамилия', placeholder: 'Иванов' },
        phone: { label: 'Телефон рақами', placeholder: 'Маълумот киритинг' },
        email: { label: 'Электрон почта', placeholder: 'example{\'@\'}domain.com' },
        password: { label: 'Панели учун парол', placeholder: '' },
        roles: {
          label: 'Рол',
          placeholder: 'Администратор',
          admin: 'Администратор',
          resident: 'Резидент',
          community_manager: 'Ҳамжамият менежери',
          buddy_partner: 'дўст-ҳамкор',
        },
        birthday: { label: 'Туғилган санаси', placeholder: '26.06.1980' },
        avatar: { label: 'Аватар' },
        badge: { label: 'Нишон', placeholder: 'Гендиректор ООО "Атом"' },
        recommendation_user: { label: 'Тавсия бўйича қўшилди', placeholder: 'Петр Петров' },
        entry: { label: 'дан бери аьзо', placeholder: '15.10.2021' },
        tag: { label: 'Теглар', placeholder: 'Тег' },
        forum_group: { label: 'Форум-гуруҳ', placeholder: 'Гуруҳ' },
        position_tag: { label: 'Клубдаги роли', placeholder: 'Модератор' },
        department: { label: 'Бўлим', placeholder: '' },
        atlas: { label: 'Атласлар', placeholder: '10' },
        city: { label: 'Шаҳар', placeholder: 'Ульяновск' },
        business_start_year: { label: 'Тажриба', placeholder: '01.03.2000' },
        business_start_year_display: { option: 'Бўлимни яшириш' },
        turnover_per_year: { label: 'Йиллик айланма, сўмда', placeholder: '6 000 000 000' },
        turnover_per_year_display: { option: 'Бўлимни яшириш' },
        number_of_employees: { label: 'Ходимлар сони', placeholder: '41' },
        number_of_employees_display: { option: 'Бўлимни яшириш' },
        business_sectors: { label: 'Бизнеснинг саноат тапмоғи', placeholder: 'Айти, телеком' },
        gender: {
          label: 'Жинс',
          option_m: 'Эркак',
          option_f: 'Аёл',
        },
        visibility: {
          show: 'Кўрсатиш',
          hide: 'Яшириш',
          range: 'Диапазон',
        },
        telegram: { label: 'Никнейм в Телеграм', placeholder: '{\'@\'}butterfly' },
        telegramId: { label: 'Телеграмдаги ID', placeholder: '' },
        vk: { label: 'Вконтакте', placeholder: 'https:/vk.com/butterfly' },
        facebook: { label: 'Фейсбук', placeholder: 'https://fb.com/butterfly' },
        instagram: { label: 'Инстаграм', placeholder: '{\'@\'}butterfly' },
        education: { label: 'Таълим', placeholder: 'МГУ, 2014-2018' },
        family_status: { label: 'Оилавий аҳвол', placeholder: 'Свободен' },
        interests: { label: 'Қизиқишлар, хоббилар', placeholder: 'Сузиш, ов қилиш..' },
        facts_about_me: { label: 'Мен ҳақимда фактлар', placeholder: '4 ёшида уйим ёнида лимонад сотардим' },
        personal_www: { label: 'Шахсий сайт', placeholder: 'domain.com' },
        video_presentation: { label: 'Видеовизитка', placeholder: 'https://youtube.com/some-video' },

        useful: 'Қандай ёрдам бера оламан',
        expertise: { label: 'Экспертиза', placeholder: 'Кимёни биламан' },
        achievements: { label: 'Ютуқлар', placeholder: 'Сертификат "Айиқча", биринчи ўрин' },
        look_resources: { label: 'Манбаалар қидириш', placeholder: 'Кальций' },

        searching: 'Нима излаяпман',
        provide_resources: { label: 'Ресурсларни тақдим этиши мумкин', placeholder: 'Калуга шаҳридаги офис жойлар' },
        year_target: { label: 'Йиллик мақсад', placeholder: '100 миллион долларлик сармояга эришиш' },
        request: { label: 'Сўров', placeholder: 'Бизнес-шерик излаяпман' },

        children: { label: 'Фарзандлар', placeholder: 'Ўғил 6 ёш, қиз 8 ёш' },
        contacts: { label: 'Боғланиш учун маълумот' },
        companies: {
          title: 'Компания',
          submit_button: 'Сақлаш',
          remove_button: 'Ўчириш',
          edit_button: 'Таҳрирлаш',
          add_button: 'Қўшиш',

          label: 'Компаниялар',
          user: { label: 'Резидент', placeholder: '' },
          name: { label: 'Компанмя номи', placeholder: 'ООО "Атом"' },
          logo: { label: 'Логотип' },
          areas: { label: 'Саноат тури', placeholder: 'Телеком' },
          position: { label: 'Компаниядаги роли', placeholder: 'CEO' },
          geography: { label: 'География', placeholder: 'Марказий Россия' },
          site: { label: 'Вебсайт', placeholder: 'example.com' },
          telegram: { label: 'Корхонанинг телеграм канали', placeholder: '{\'@\'}company' },
          wasteTypes: { label: 'Қайта ишланадиган чиқиндилар турлари', placeholder: '' },
        },
        status: {
          label: 'Статус',
          active: 'Актив',
          blocked: 'Блокланган',
          left: 'Клубни тарк этган',
          not_paid: 'Тўланмаган',
        },
        newPassword: {
          label: 'Доимий пароль',
          placeholder: 'Парольни кўрсатинг',
        },
        authMethods: {
          label: 'Авторизация тури',
          placeholder: 'Камида битта авторизация турини танланг',
        },
        externalId: {
          label: 'GetCourse-да фойдаланувчи идентификатори',
          placeholder: 'Идентификаторни киритинг',
        },
        description: {
          label: 'Резидентнинг дўст-ҳамкор сифатида ҳусусияти',
          placeholder: 'Тавсиф',
        },
      },
      companies: {
        table: {
          title: 'Резидентнинг корхонаси',
          not_found: 'Компания топилмади',
          name: 'Ном',
          logo: 'Логотип',
          user_position: 'Компаниядаги мажбурияти',
          areas: 'Фаолият соҳалари',
          error: 'Корхонани юклашни иложи бўлмади',
        },
        filters: {
          name: 'Ном',
        },
      },
    },
    mobile_profile: {
      sidebar: {
        title: 'Майдон редактори',
      },
      form: {
        title: 'Майдон редактори',
      },
    },
    cabinet_sections: {
      sidebar: {
        title: 'Разделлар кабинети',
      },
    },
    preferences: {
      sidebar: {
        title: 'Созламалар',
      },
    },
    reports: {
      sidebar: {
        title: 'Хисоботлар',

        user_activity_title: 'Фойдаланувчи фаолияти',
        events_title: 'Тадбирлар',
      },
    },
    auth: {
      sidebar: {
        table_title: 'Авторизациялар рўйхати',
      },
    },
    appearance: {
      sidebar: {
        title: 'Кўришидаги қиймат',
      },
      form: {
        title: 'Кўришидаги қиймат',
      },
    },
    // TODO Нужен перевод
    link_groups: {
      sidebar: {
        table_title: 'Ҳавола гуруҳлари',
      },
      table: {
        title: 'Ҳавола гуруҳлари',
        name: 'Название',
        actions: '',
        edit_action: 'Редактировать',
      },
      form: {
        title_edit: 'Редактирование ссылки',
        submit_button: 'Сохранить',
        name: { label: 'Название', placeholder: 'Название ссылки' },
        position: { label: 'Позиция', placeholder: 'Номер позиции' },
        visible: 'Видимое',
        failed_edition: 'Не удалось отредактировать ссылку',
      },
    },
    club_role: {
      sidebar: {
        table_title: 'Клубдаги роли',
      },
      table: {
        title: 'Клубдаги роли',
        not_found: 'Рол топилмади',
        id: 'ID',
        name: 'Ном',
        actions: '',
        edit_action: 'Таҳрирлаш',
      },
      filters: {
        create_new: 'Яратиш',
        submit: 'Тасдиқлаш',
        name: 'Ном бўйича',
      },
      form: {
        title_create: 'Янги рол',
        title_edit: 'Ролни таҳрирлаш',
        submit_button: 'Сақлаш',
        remove_button: 'Ўчириш',
        failed_creation: 'Ролни яратишни иложи бўлмади',
        failed_edition: 'Ролни таҳрирлаб бўлмади',
        name: { label: 'Ном', placeholder: 'Қўриқчи' },
        color: { label: 'Ранг', placeholder: '#FFFFFF' },
      },
    },
    event_category: {
      sidebar: {
        group_title: 'Тадбир тури',
        table_title: 'Тадбир тури',
      },
      table: {
        title: 'Тадбир тури',
        not_found: 'Тур топилмади',
        edit_action: 'Таҳрирлаш',
        id: 'ID',
        name: 'Ном',
        remove: 'Ўчириш',
        actions: '',
      },
      filters: {
        create_new: 'Яратиш',
        submit: 'Тасдтқлаш',
        name: 'Ном бўйича',
      },
      form: {
        title: 'Янги тур',
        failed_creation: 'Турни яратишни иложи бўлмади',
        failed_edition: 'Ролни яратишни иложи бўлмади',
        submit_button: 'Сақлаш',
        remove_button: 'Ўчириш',
        name: { label: 'Ном', placeholder: 'Йиғилиш' },
      },
    },
    partner: {
      sidebar: {
        table_title: 'Ҳамкорлар',
      },
      table: {
        title: 'Ҳамкорлар',
        not_found: 'Ҳамкорлар топилмади',
        id: 'ID',
        name: 'Ном',
        actions: '',
        edit_action: 'Таҳрирлаш',
      },
      filters: {
        create_new: 'Яратиш',
        submit: 'Тасдиқлаш',
        name: 'Ном бўйича',
      },
      form: {
        title_create: 'Янги ҳамкор',
        title_edit: 'Ҳамкорни таҳрирлаш',
        submit_button: 'Сақлаш',
        remove_button: 'Ўчириш',
        failed_creation: 'Ҳамкорни яратишни иложи бўлмади',
        failed_edition: 'Ҳамкорни таҳрирлашни иложи бўлмади',
        name: { label: 'Ном', placeholder: 'ООО "Компания"' },
        description: { label: 'Тавсиф', placeholder: '' },
        url: { label: 'Ҳавола', placeholder: 'https://t.me/company' },
        group: { label: 'Ҳаволалар гуруҳи', placeholder: 'Выбрать' },
        image: { label: 'Сурат' },
      },
    },
    club_in_club: {
      sidebar: {
        table_title: 'Клубдаги клублар',
      },
      table: {
        title: 'Клубдаги клублар',
        not_found: 'Клублар топилмади',
        id: 'ID',
        name: 'Ном',
        actions: '',
        edit_action: 'Таҳрирлаш',
      },
      filters: {
        create_new: 'Яратмш',
        submit: 'Тасдиқлаш',
        name: 'Ном бўйича',
      },
      form: {
        title_create: 'Клуб ичидаги янги клуб',
        title_edit: 'Клубни таҳрирлаш',
        submit_button: 'Сақлаш',
        remove_button: 'Ўчириш',
        failed_creation: 'Клуб яратиб бўлмади',
        failed_edition: 'Клубни таҳрирлаб бўлмади',
        name: { label: 'Ном', placeholder: 'автомобил ихлосмандлари' },
        description: { label: 'Тавсиф', placeholder: '' },
        url: { label: 'Ҳавола', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Ҳаволалр гуруҳи', placeholder: 'Выбрать' },
        image: { label: 'Сурат' },
      },
    },
    complaints: {
      sidebar: {
        table_title: 'Шикоятлар ва блокировкалар',
      },
      table: {
        title: 'Шикоятлар',
        complaintAuthor: 'Ким шикоят қилди',
        complaintText: 'Шикоят матни',
        complaintObject: 'Шикоят объекти',
        commentary: 'Муаллиф шарҳи/нашр қилинган.',
        complaintTime: 'Шикоят вақти',
        complaintActions: 'Контроллерлар',
        complaintObserve: 'Шикоятни кўриб чиқиш',
      },
      filters: {
        complaints: 'Шикоятлар',
        bans: 'Блокировкалар',
      },
      form: {
        title_create: 'Клуб ичидаги янги клуб',
        title_edit: 'Клубни таҳрирлаш',
        submit_button: 'Сақлаш',
        remove_button: 'Ўчириш',
        failed_creation: 'Клуб яратиб бўлмади',
        failed_edition: 'Клубни таҳрирлаб бўлмади',
        name: { label: 'Ном', placeholder: 'автомобил ихлосмандлари' },
        description: { label: 'Тавсиф', placeholder: '' },
        url: { label: 'Ҳавола', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Ҳаволалр гуруҳи', placeholder: 'Выбрать' },
        image: { label: 'Сурат' },
      },
    },
    bans: {
      sidebar: {
        table_title: 'Блокировкалар',
      },
      table: {
        title: 'Блокировкалар',
        company: 'Компания',
        author: 'Асосчининг ФИШ',
        created: 'Яратилган санаси',
        actions: 'Контроллерлар',
        remove: 'Блокировкани йечиш',
      },
      filters: {
        complaints: 'Шикоятлар',
        bans: 'Блокировкалар',
      },
      form: {
        title_create: 'Клуб ичидаги янги клуб',
        title_edit: 'Клубни таҳрирлаш',
        submit_button: 'Сақлаш',
        remove_button: 'Ўчириш',
        failed_creation: 'Клуб яратиб бўлмади',
        failed_edition: 'Клубни таҳрирлаб бўлмади',
        name: { label: 'Ном', placeholder: 'автомобил ихлосмандлари' },
        description: { label: 'Тавсиф', placeholder: '' },
        url: { label: 'Ҳавола', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Ҳаволалр гуруҳи', placeholder: 'Выбрать' },
        image: { label: 'Сурат' },
      },
    },
    community_manager: {
      sidebar: {
        table_title: 'Ҳамжамият менежерлари',
      },
      table: {
        title: 'Ҳамжамият менежерлари',
        not_found: 'Ҳамжамият менежерлари топилмади',
        fio: 'ФИЩ',
        email: 'Е-мейл',
        phone: 'Телефон рақами',
        birthday: 'Туғилган кун',
        avatar: 'Аватар',
        community_manager: 'Жамият',
        is_community_manager: 'Жамоадир',
        change_community_manager: 'Менежерни ўзгартириш',
        status: 'Ҳолат',
      },
      form: {
        title: 'Ҳамжамият менежерига ҳавола',
        failed_action: 'Уланиш тугалланмади',
        submit_button: 'Сақлаш',
      },
      filters: {
        community_manager: 'Ҳамжамият менежерлари',
        name: 'Резидентлар бўйича қидирув',
      },
      layout: {
        title: 'Ҳамжамият менежерига ҳавола',
        sub_title: 'Танланган резидентлар',
        placeholder: 'Менежерни танланг',
        loading: 'Юклаш',
        link: 'боғлаш',
        unlink: 'узиш',
      },
    },
    buddy_partner: {
      sidebar: {
        table_title: 'дўст ҳамкор',
      },
      table: {
        title: 'дўст ҳамкорлар',
        not_found: 'дўст ҳамкорлар топилмади',
        fio: 'ФИШ',
        email: 'Е-мейл',
        phone: 'Телефон рақами',
        birthday: 'Туғилган кун',
        avatar: 'Аватар',
        buddy_partner: 'дўст ҳамкор',
        is_buddy_partner: 'дўст ҳамкоридир',
        change_buddy_partner: 'Шеригини ўзгартиринг',
        status: 'Статус',
      },
      form: {
        title: 'Шерикка боғлаш',
        failed_action: 'Уланиш тугалланмади',
        submit_button: 'Сақлаш',
      },
      filters: {
        buddy_partner: 'дўст ҳамкорлар',
        name: 'Резидентлар бўйича қидирув',
      },
    },
    notification: {
      sidebar: {
        table_title: 'Ахборот бюллетенлари',
      },
      table: {
        title: 'тарқатма',
        caption: 'сарлавҳа',
        actions: 'Ҳаракатлар',
        edit_action: 'Таҳрирлаш',
        createAt: 'яратилган сана',
        notifyAt: 'Юборилган сана',
        published: 'Нашр',
      },
      filters: {
        create_new: 'Янги хабарнома',
      },
      form: {
        title: 'Тарқатма',
        cancel_button: 'Бекор қилиш',
        submit_button: 'сақлаш',
        remove_button: 'Ўчириш',
        caption: {
          label: 'хабарнинг сарлавҳаси',
          placeholder: 'Матн киритинг',
        },
        text: {
          label: 'Хабар матни',
          placeholder: 'Матн киритинг',
        },
        date: {
          label: 'Етказиб бериш санаси ва вақти',
        },
        status: 'Тарқатмалар статуси',
        draft: 'Қоралама',
        types: {
          all: 'Ҳаммаси',
          users: 'Резидентлар рўйхати',
          group: 'Клубдаги роли',
        },
        users_list_type: 'Тарқатмалар қабул қилувчилари',
        users: {
          label: 'Резидентларни танланг',
          placeholder: 'Исм ёзишни бошланг...',
        },
        groupId: {
          label: 'Клубдаги роли',
          placeholder: 'Рол танланг',
        },
        deeplink: {
          label: 'Диплинк',
          placeholder: 'Ҳаволани танланг',
        },
        events: {
          label: 'Тадбир',
          placeholder: 'Тадбирни танланг',
        },
        posts: {
          label: 'Хабарлар',
          placeholder: 'Постни танланг',
        },
      },
      status: {
        draft: 'Қоралама',
        planned: 'Режалаштирилган',
        completed: 'Бажарилди',
        failed: 'Хато',
      },
    },
  },
  gaz: {
    import: {
      button: 'Импорт',
      title: 'Импортировать резидентов',
      error: 'Ошибка',
    },
    interview: {
      sidebar: {
        table_title: 'Сўровлар',
        card_title: 'Тадқиқотлар',
      },
      table: {
        title: 'Сўровлар',
        not_found: 'Сўровлар топилмади',
        id: 'ID',
        name: 'Ном',
        description: 'Тавсиф',
        createdAt: 'яратилган сана',
        actions: 'Ҳаракатлар',
        published: 'Публикация',
        is_published: 'нашр этилган',
        is_not_published: 'нашр этилмаган',
      },
      filters: {
        create_new: 'Яратиш',
        submit: 'Тасдиқлаш',
        name: 'Ном бўйича',
      },
      form: {
        title: 'Сўровнома',
        failed_creation: 'Сўровнома яратилмади',
        submit_button: 'Сақлаш',
        cancel_button: 'Бекор қилиш',
        remove_button: 'Ўчириш',
        edit_button: 'Таҳрирлаш',
        name: { label: 'Ном', placeholder: 'Тадбирни баҳолаш' },
      },
      components: {
        condition: {
          question_select: {
            placeholder: 'Қадам ва саволни танланг',
            step_label: 'Қадам',
            question_label: 'саволни',
          },
          add_condition_btn: 'Шарт',
          add_block_btn: 'Блок',
          confirm: {
            message: 'Ҳақиқатан ҳам бу шартни олиб ташлайсизми??',
            title: 'Диққат',
            confirm: 'Ҳа,олиб ташлайман ',
            cancel: 'Бекор қилиш',
          },
        },
      },
      constants: {
        question_type: {
          stars: 'Баҳолаш',
          plain_answer: 'Матн майдони (узун/қисқа)',
          list: 'Рўйхат',
          date: 'Сана/вақт',
          file: 'Юклаш',
        },
        data_type: {
          string: 'Чизиқ',
          number: 'Рақам',
        },
        length_options: {
          short: 'Қисқа',
          long: 'Узун',
        },
        date_type: {
          date_time: 'Сана/вақт',
          time: 'вақт',
        },
        file_type: {
          photo: 'Фото',
          document: 'Документ',
        },
      },
      layout: {
        name: 'Сўровноманинг номи',
        global_limit: 'Глобал чегара',
        // TODO Нужен перевод
        global_limit_hint: 'Лимит имеет более высокий приоритет, чем в Мероприятии. При его применении, лимит в Мероприятии будет игнорироваться.',
        description: 'Сўровнинг тавсифи',
        price: 'Қўшиш. нарх',
        step: 'Қадам',
        question: 'Савол',
        confirm_question: {
          message: 'Ҳақиқатан ҳам бу савол ўчирилсинми?',
          title: 'Диққат',
          confirm: 'Ҳа, ўчирилсин',
          cancel: 'Бекор қилиш',
        },
        confirm_step: {
          message: 'Ҳақиқатан ҳам бу қадам ўчирилсинми??',
          title: 'Диққат',
          confirm: 'Ҳа, ўчирилсин',
          cancel: 'Бекор қилиш',
        },
        confirm_move: {
          message: 'Агар сиз ушбу қадамни кўчирсангиз, сўровномадаги ҲАММА кўрсатиш шартлари ўчирилади. Давом эттирилсинми?',
          title: 'Диққат',
          confirm: 'Ҳа, кўчирилсин',
          cancel: 'Бекор қилиш',
        },
        confirm_move_question: {
          message: 'Агар сиз ушбу саволни бошқа жойга кўчирсангиз, сўровномадаги БАРЧА кўрсатиш шартлари ўчирилади. Давом эттирилсинми?',
          title: 'Диққат',
          confirm: 'Ҳа, кўчирилсин',
          cancel: 'Бекор қилиш',
        },
        confirm_price: {
          message: 'Барча харажат созламалари тикланади. Давом эттирилсинми?',
          title: 'Диққат',
          confirm: 'Ҳа',
          cancel: 'Бекор қилиш',
        },
        modal_question: {
          title: 'Савол турини танланг',
          label: 'Савол тури',
          cancel: 'Бекор қилиш',
          ok: 'Танлаш',
        },
        modal_price: {
          title: 'Нархни белгилаш',
          free: 'Бепул тадбир',
          paid: 'Пулли тадбир',
          total_sum: 'Якуний харажат',
          base_price: 'базавий харажат',
          question: 'Савол',
          term: 'бўлим',
        },
      },
      question: {
        title: 'Савол',
        placeholder_question: 'Саволни шакллантириш',
        placeholder_description: 'Қисқа тавсифни киритинг',
        label: 'Мажбурий савол',
      },
      list_question: {
        confirm_delete: {
          message: 'Ҳақиқатан ҳам бу жавоб варианти ўчирилсинми?',
          title: 'Диққат',
          confirm: 'Ҳа',
          cancel: 'бекор қилиш',
        },
        title: 'Маълумотлар тури',
        self_answer: 'Резидент ўзининг жавоб вариантини киритиши мумкин',
        multi_select: 'Резидент бир нечта жавобларни танлаши мумкин',
        total_limit: 'Умумий чегара',
        // TODO Нужен перевод
        total_limit_hint: 'Общий лимит для всех участников мероприятия по отношению к определенному вопросу.',
        show_new_screen: 'Жавоблар рўйхатини МТ да янги экранда очинг',
        option_placeholder: 'Жавобни киритинг',
        add_button: 'Мумкин жавоб',
      },
      plain_answer_question: {
        title: 'Маълумотлар тури',
        text_length: 'Текст узунлиги',
        int_limit: 'Чегара',
        // TODO Нужен перевод
        int_limit_hint: 'Лимит ограничивает максимальное количество выбранной опции (например, билетов) для одного пользователя, проходящего опрос.',
        total_limit: 'Умумий лимит',
        // TODO Нужен перевод
        total_limit_hint: 'Общий лимит ограничивает сумму вводимых всеми пользователями значений относительно выбранного вопроса.',
      },
      file_question: {
        max_size: 'Файлнинг максимал ҳажми МБ',
        warning_1: 'Ҳар бир саволга фақат битта файл бириктирилиши мумкин.',
        warning_2: 'Диққат! Файллар серверда қўшилган кундан бошлаб 1 йил давомида сақланади',
      },
    },
    profile: {
      sidebar: {
        title: 'Кабинет',
        table_title: 'Бўлимлар бўйича тақсимлаш',
        card_title: 'Янги бўлим',
        group_title: 'Кабинет',
      },
      table: {
        title: 'Бўлимлар бўйича тақсимлаш',
        not_found: 'Бўлимлар топилмади',
        id: 'ID',
        name: 'Кичик бўлим',
        departments: 'Филиаллар',
        actions: '',
        edit_action: 'Таҳрирлаш',
      },
      filters: {
        create_new: 'Яратмоқ',
      },
      form: {
        title_create: 'Янги бўлим',
        title_edit: 'Бўлимни таҳрирлаш',
        submit_button: 'Сақлаш',
        remove_button: 'Ўчириш',
        failed_creation: 'Кичик бўлимни яратиб бўлмади',
        failed_edition: 'Кичик бўлимни таҳрирлаб бўлмади',
      },
    },
    feedback: {
      sidebar: {
        table_title: 'Электрон қабул қилиш',
      },
      table: {
        title: 'Электрон юридик қабул',
        not_found: 'Ёзувлар топилмади',

        fio: 'ФИШ',
        email: 'E-mail',
        text: 'Резидентнинг мурожаати матни',
        createdAt: 'яратилган сана',
        action_more: 'Кўпроқ',
      },
      tabs: {
        suggestions: 'Ўзгаришлар бўйича таклифлар',
        breaking: 'Ҳуқуқбузарликлар ҳақидаги даъволар',
      },
      filters: {
        text: 'Чизиқлар бўйича қидириш',
      },
    },
    boss_word: {
      sidebar: {
        card_title: 'Раиснинг сўзи',
      },
      form: {
        submit_button: 'Сақлаш',
        cancel_button: 'Орқага',

        image: {
          label: 'Расм',
          hint: 'Ҳажм 100мб гача',
        },
        title: {
          label: 'Сарлавҳа',
          placeholder: 'Сарлавҳа матнини киритинг',
        },
        text: {
          label: 'Асосий матн',
          placeholder: 'Матннм киритинг',
        },
      },
    },
    safety: {
      sidebar: {
        table_title: 'Меҳнат муҳофазаси',
      },
      table: {
        title: 'Меҳнатни муҳофаза қилиш ва саноат хавфсизлиги',
        not_found: 'Таклифлар топилмади',

        fio: 'ФИШ',
        email: 'E-mail',
        organization: 'Ташкилот',
        regulation_act: 'Маҳаллий тартибга солиш',
        point: 'Қонун параграф рақами',
        text: 'Сизнинг таклифингиз',
        text_breaking: 'Бузилишнинг тавсифи',
        createdAt: 'яратилган сана',
        place: 'Бузилиш аниқланган объект',
        action_more: 'Кўпроқ',
      },
      tabs: {
        suggestions: 'Ўзгаришлар бўйича таклифлар',
        breaking: 'Ҳуқуқбузарликлар ҳақидаги даъволар',
      },
      filters: {
        text: 'Чизиқлар бўйича қидириш',
      },
    },
  },
};

export type CustomTranslationSchema = typeof uzCustom;
export default uzCustom;
