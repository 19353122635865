import { translate } from 'magner';
import { autocompleteSearch } from 'features/residents/requests/hubstr';

export const companyNameField = {
  type: 'input',
  name: 'name',
  label: translate('hubstr.resident.form.companies.name.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.companies.name.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const companyTurnOverField = {
  type: 'input',
  name: 'associationTurnoverPerYear',
  dataType: 'number',
  label: translate('hubstr.resident.form.turnover_per_year.label'),
  props: {
    placeholder: translate('hubstr.resident.form.turnover_per_year.placeholder'),
  },
  validation: [{
    type: 'is-integer',
    trigger: 'blur',
  }],
};

export const numberOfEmployeesField = {
  type: 'input',
  name: 'associationNumberOfEmployees',
  dataType: 'number',
  label: translate('hubstr.resident.form.number_of_employees.label'),
  props: {
    placeholder: translate('hubstr.resident.form.number_of_employees.placeholder'),
  },
  validation: [{
    type: 'is-integer',
    trigger: 'blur',
  }],
};

export const companyLogoField = {
  type: 'dropzone',
  name: 'logo',
  label: translate('hubstr.resident.form.companies.logo.label'),
  props: {
    srcKey: 'big',
  },
};

export const companyAreasField = {
  type: 'select',
  name: 'associationAreas',
  label: translate('hubstr.resident.form.companies.areas.label'),
  options: [],
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('associationAreas'),
    required: true,
    placeholder: translate('hubstr.resident.form.companies.areas.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const companyPositionField = {
  type: 'select',
  name: 'associationUserPosition',
  label: translate('hubstr.resident.form.companies.position.label'),
  options: [],
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('associationUserPosition'),
    required: true,
    placeholder: translate('hubstr.resident.form.companies.position.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const companiesGeography = {
  type: 'textarea',
  name: 'associationGeography',
  label: translate('hubstr.resident.form.companies.geography.label'),
  props: {
    placeholder: translate('hubstr.resident.form.companies.geography.placeholder'),
    rows: 3,
  },
};

export const companySiteField = {
  type: 'input',
  name: 'associationSite',
  label: translate('hubstr.resident.form.companies.site.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.companies.site.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const companyTelegramField = {
  type: 'input',
  name: 'associationTelegram',
  label: translate('hubstr.resident.form.companies.telegram.label'),
  props: {
    placeholder: translate('hubstr.resident.form.companies.telegram.placeholder'),
  },
};
