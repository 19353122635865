import { translationController } from 'magner';
import ruCustom from 'configs/translation/ru';
import enCustom from 'configs/translation/en';
import ruElLocale from 'element-plus/lib/locale/lang/ru';
import enLocale from 'element-plus/lib/locale/lang/en';
import uzLocale from 'element-plus/lib/locale/lang/uz-uz';
import uzCustom from 'configs/translation/uz';
import { translatesGet } from 'features/settings/mobile-editor/requests';
import {
  APP_ID,
  APP_MILLIARD,
  APP_HUBSTR,
  META_APP,
  APP_LANG,
  APP_COMMON,
} from '~/constants';

// eslint-disable-next-line no-shadow
export enum SupportedLanguages {
  RUS = 'ru',
  ENG = 'en',
  UZB = 'uz'
}

// в цикле не работает
const resRu = await translatesGet('ru');
ruCustom.dynamic = resRu.data;
const resEn = await translatesGet('en');
enCustom.dynamic = resEn.data;
const resUz = await translatesGet('uz');
uzCustom.dynamic = resUz.data;

const i18n = translationController<SupportedLanguages>((() => {
  switch (APP_ID) {
    case APP_MILLIARD: {
      return {
        mainLanguage: 'uz',
        fallbackLanguage: 'ru',
        languages: {
          uz: 'O\'zbek',
          ru: 'Русский',
          en: 'English',
        },
        translation: {
          ru: ruCustom,
          en: enCustom,
          uz: uzCustom,
        },
        elLocales: {
          ru: ruElLocale,
          en: enLocale,
          uz: uzLocale,
        },
      };
    }
    case APP_HUBSTR: {
      return {
        mainLanguage: APP_LANG || 'ru',
        fallbackLanguage: 'ru',
        languages: {
          en: 'English',
          ru: 'Русский',
        },
        translation: {
          ru: ruCustom,
          en: enCustom,
        },
        elLocales: {
          ru: ruElLocale,
          en: enLocale,
        },
      };
    }
    case META_APP: {
      return {
        mainLanguage: APP_LANG || 'ru',
        fallbackLanguage: 'ru',
        languages: {
          en: 'English',
          ru: 'Русский',
        },
        translation: {
          ru: ruCustom,
          en: enCustom,
        },
        elLocales: {
          ru: ruElLocale,
          en: enLocale,
        },
      };
    }
    case APP_COMMON: {
      return {
        mainLanguage: APP_LANG || 'ru',
        fallbackLanguage: 'ru',
        languages: {
          uz: 'O\'zbek',
          ru: 'Русский',
          en: 'English',
        },
        translation: {
          ru: ruCustom,
          en: enCustom,
          uz: uzCustom,
        },
        elLocales: {
          ru: ruElLocale,
          en: enLocale,
          uz: uzLocale,
        },
      };
    }
    default: {
      return {
        mainLanguage: 'ru',
        fallbackLanguage: 'ru',
        languages: {
          ru: 'Русский',
        },
        translation: {
          ru: ruCustom,
        },
        elLocales: {
          ru: ruElLocale,
        },
      };
    }
  }
})() as any);

export default i18n;
