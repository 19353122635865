export const APP_ID = import.meta.env.VITE_APP_ID;
export const APP_VERSION = import.meta.env.VITE_APP_VERSION;

export const API_URL = (APP_ID === 'meta' || APP_ID === 'common') && APP_VERSION !== 'DEV'
  ? window.location.origin
  : import.meta.env.VITE_APP_API_URL as string;

export const APP_SETTINGS_EDITOR_ON = !!+import.meta.env.VITE_APP_SETTINGS_EDITOR_ON || false as boolean;
export const APP_LANG = import.meta.env.VITE_APP_LANG;
// eslint-disable-next-line no-shadow
export enum ROLE {
  ADMIN = 'ROLE_ADMIN'
}

export const APP_HUBSTR = 'hubstr';
export const APP_ETALON = 'etalon';
export const APP_UDSCHOOL = 'udschool';
export const APP_COMMUNITY_CENTER = 'community_center';
export const APP_CLOVER = 'clover';
export const APP_TAXI_369 = 'taxi369';
export const APP_GAZPROM = 'gaz';
export const APP_CODE_PILOTS = 'code_pilots';
export const APP_MILLIARD = 'milliard';
/** Meta */
export const META_APP_LC = 'lc';
export const APP_DP = 'dp';
export const APP_CILICIA = 'cilicia';
export const META_APP = 'meta';
export const APP_COMMON = 'common';

export const siteTitle = (appId: string) => {
  switch (appId) {
    case APP_ETALON: {
      return 'Бизнес-клуб "Эталон"';
    }
    case APP_UDSCHOOL: {
      return 'Умный Дизайн';
    }
    case APP_COMMUNITY_CENTER: {
      return 'Центр Сообществ';
    }
    case APP_CLOVER: {
      return 'Клевер';
    }
    case APP_TAXI_369: {
      return 'Группа компаний 369';
    }
    case APP_GAZPROM: {
      return 'Газпром. Профсоюз';
    }
    case APP_CODE_PILOTS: {
      return 'Code Pilots';
    }
    case APP_MILLIARD: {
      return 'Миллиард';
    }
    case META_APP: {
      return 'Meta Hubstr';
    }
    default: {
      return 'Hubstr';
    }
  }
};

export const SUB_DOMEN = document.location.hostname.split('.')[0];

export const COMMON_LIST = [
  APP_ETALON,
  APP_CODE_PILOTS,
  APP_TAXI_369,
  APP_UDSCHOOL,
  APP_MILLIARD,
  APP_CLOVER,
];

// TODO:: move it somewhere else. Don't know where
export const imageUploadCode = 'link_logo';
