import { AdditionalField, Widgets } from 'features/settings/mobile-editor/interfaces';
import { ADMIN_GROUP_TYPES, GROUP_TYPES } from 'features/settings/mobile-editor/constants';
import MobileFieldEntity from 'features/settings/mobile-editor/factories/mobile-field-entity';
import MobilePrivacyEntity from 'features/settings/mobile-editor/factories/mobile-privacy-entity';
import { findMatchingField } from 'features/settings/mobile-editor/utils/find-matching-field';

const contacts = ['vk', 'telegram', 'instagram', 'facebook'];

/**
 * @param view
 * @param additionsList
 * */
const parseForMobile = (view: Widgets, additionsList: AdditionalField[]) => {
  const mobileView = JSON.parse(JSON.stringify(view)) as Widgets;
  /**
   * Первый раз мы пробегаемся по списку групп и ищем Социальные сети
   * их надо будет впихнуть в раздел контакты =(
   */
  const socials = mobileView.body.filter((group) => group.type === GROUP_TYPES.SOCIAL);

  /**
   * Если группа контактов отсуствует. Создаем ее.
   * (Далее она будет наполнена из socials (если они есть))
   */
  if (socials.length && !mobileView.body.map((g) => g.adminGroupType).includes(ADMIN_GROUP_TYPES.CONTACTS as any)) {
    mobileView.body.push({
      type: 'group',
      adminGroupType: ADMIN_GROUP_TYPES.CONTACTS,
      name: null,
      title: 'lang.contacts',
      widgets: [],
    });
  }

  const newView = mobileView.body
    .filter((group) => group.type !== GROUP_TYPES.SOCIAL)
    .map((group) => {
      const newWidgets = group.widgets.flatMap((widget) => {
        // Ищем соответствующее(виджету) поле в списке доп. полей
        const matchingField = findMatchingField(widget, additionsList);

        if (matchingField) {
          const fieldFactoryInit = new MobileFieldEntity(widget, matchingField);
          const privacyFactoryInit = new MobilePrivacyEntity(matchingField);

          // Генерируем поле
          const out = [fieldFactoryInit.create()];

          // Добавляем приватность
          if (widget.hasPrivacy) {
            out.push(privacyFactoryInit.create(matchingField.format));
          }

          return out;
        }

        // особенное поле день рождение
        if (widget.text === 'data.formattedBirthday.value') {
          const out = [{
            type: 'datePicker',
            name: 'birthday',
            value: 'data.birthday',
            title: widget.title,
            required: true,
            validator: {
              maxDate: {
                date: '2007-08-17 21:18:49.385714',
                timezone_type: 3,
                timezone: 'Europe/Moscow',
              },
            },
          }];

          if (widget.hasPrivacy) {
            out.push({
              list: {
                DEFAULT: 'Полностью',
                NONE: 'Не показывать',
                DAY_MONTH: 'Только месяц и день',
              },
              name: 'birthdayFormat',
              type: 'privacyList',
              title: 'Приватность',
              value: 'data.formattedBirthday.format',
              default: 'DEFAULT',
            } as never);
          }

          return out;
        }

        if (contacts.includes(widget.name as string)) {
          return {
            type: 'text',
            name: widget.name,
            value: `data.contacts.${widget.name}`,
            title: `lang.${widget.name}`,
            placeholder: '@nick или https://...',
            required: false,
            isMultiline: false,
            validator: {
              type: 'text',
              maxLength: 128,
            },
          };
        }
      }).filter((w) => w);

      /**
       * Если группа - контакты, то надо добавить тудой социальные сети. Но телега не должна дублироваться
       */
      if (group.adminGroupType === ADMIN_GROUP_TYPES.CONTACTS && socials.length) {
        socials[0].widgets.map((w) => {
          if (!newWidgets.map((nw) => nw.name).includes(w.name)) {
            newWidgets.push({
              type: 'text',
              name: w.name,
              value: `data.contacts.${w.name}`,
              title: `lang.${w.name}`,
              placeholder: '@nick или https://...',
              required: false,
              isMultiline: false,
              validator: {
                type: 'text',
                maxLength: 128,
              },
            });
          }
        });
      }

      // Добавляем приватность телефона в группу контакты
      if (group.adminGroupType === ADMIN_GROUP_TYPES.CONTACTS) {
        const privacyFactoryInit = new MobilePrivacyEntity([]);
        const hasPhone = group.widgets.some((w) => (w.name === 'phone'));

        newWidgets.push(privacyFactoryInit.setPhonePrivacy(hasPhone));
      }

      return {
        ...group,
        /**
       * GROUP_TYPES.GROUP и GROUP_TYPES.SOCIAL конвертируем в group
       */
        type: group.type === GROUP_TYPES.GROUP || group.type === GROUP_TYPES.SOCIAL ? 'group' : group.type,
        fields: newWidgets.filter((w) => w),
      };
    }) as any;

  // Удаляем лишний объект widgets и adminGroupType
  newView.forEach((group: any) => {
    delete group.widgets;
    delete group.adminGroupType;
  });
  // Удаляем GROUP_TYPES.COMMUNITIES
  const outBody = newView.filter((group: any) => group.type !== GROUP_TYPES.COMMUNITIES);

  const out = {
    name: 'profile_edit',
    action: '/api/residents/profile/save',
    method: 'post',
    fields: [
      {
        type: 'inline',
        fields: [
          {
            type: 'image_upload',
            name: 'avatar',
            imageType: 'resident_avatar',
            required: true,
            value: 'data.avatar',
          },
          {
            type: 'text',
            name: 'firstName',
            value: 'data.firstName',
            title: 'lang.firstName',
            placeholder: 'lang.enter_info',
            required: true,
            isMultiline: false,
            validator: {
              type: 'text',
              minLength: 1,
              maxLength: 24,
            },
          },
        ],
      },
      {
        type: 'text',
        name: 'lastName',
        value: 'data.lastName',
        title: 'lang.lastName',
        placeholder: 'lang.enter_info',
        required: true,
        isMultiline: false,
        validator: {
          type: 'text',
          minLength: 1,
          maxLength: 24,
        },
      },
      ...outBody,
    ],
  };

  const badge = mobileView.header.find((h) => h.type === 'header_badge_widget');
  if (badge) {
    out.fields.splice(2, 0, {
      type: 'text',
      name: 'additions[userBadge]',
      value: 'data.additions.userBadge',
      title: badge?.label || 'Бейдж',
      placeholder: 'lang.badge_hint',
      required: badge?.required || false,
      multiline: false,
      validator: {
        type: 'text',
        maxLength: 150,
      },
    });
  }

  const forumGroup = mobileView.footer.some((f) => f.type === 'form_group_widget');
  if (forumGroup) {
    out.fields.splice(3, 0, {
      type: 'text',
      name: 'additions[userFormGroup]',
      value: 'data.additions.userFormGroup',
      title: 'lang.userFormGroup',
    });
  }
  return out;
};
export default parseForMobile;
